var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../buildchain/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TypeaheadComponent.vue?vue&type=custom&index=0&blockType=vue-bootstrap-typeahead&v-model=query&%3Adata=%5B'Canada'%2C%20'USA'%2C%20'Mexico'%5D"
if (typeof block0 === 'function') block0(component)

export default component.exports