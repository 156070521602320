import 'slick-carousel';
var arrow_right = '<svg id="arrow_right" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M0.5 10L18.8 10"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M14.8 6L18.8 10 14.8 14"/></svg>';
var arrow_left= '<svg id="arrow_left" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M19.6,10H1.4"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M5.3,14l-4-4l4-4"/></svg>';
var bp_sm = '800';
$(document).ready(function() {
  initTestimonialsSliders();
});

var testimonials_instances = [];

var initTestimonialsSliders = function() {

  var testimonials = $('.testimonials-container');

  if ( testimonials.length ) {
    testimonials.each(function(){
      testimonials_instances.push(initTestimonialsSlider($(this)));
    })

  }
}
var destroyTestimonialsSliders = function() {
  var instance;
  while (testimonials_instances.length) {
    instance = testimonials_instances.shift();
    if (instance == undefined) { return; }
    instance.destroy();
  }
}


var initTestimonialsSlider = function(container) {

  var testimonials = {

    initialized: false,
    container: null,
    slider: null,
    speed: 800,

    draw: function(){
      var _this = this;

      _this.el =  _this.slider.slick({
        infinite: true,
        autoplay: true,
        fade: true,
        speed: _this.speed,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        // appendArrows: container.find('.slider-arrows'),
        prevArrow: '<button type="button" class="slider-prev btn-style-none" aria-label="Previous">'+arrow_left+'</button>',
        nextArrow: '<button type="button" class="slider-next btn-style-none" aria-label="Next">'+arrow_right+'</button>',
        draggable: true,
        pauseOnHover: true
      });
    },

    destroy: function() {
      var _this = this;
      if ( !_this.el ) return;
      _this.el.slick('unslick');
      _this.initialized = false;
    },

    reset: function() {
      var _this = this;
      _this.destroy();
      _this.draw();
    },
    init: function(container) {
      var _this = this;

      if (container === undefined || container.length < 1) return;

      _this.container = container;
      _this.slider = _this.container.find('.testimonials-slider');

      if (_this.slider.length < 1) return;

      // === Events
      _this.slider.on('init', function (slick) {
        _this.initialized = true;
        _this.container.removeClass('loading');
      });

      _this.draw();

      return _this;
    }
  }
  return testimonials.init(container);
}
