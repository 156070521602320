import Vue from "vue";

const components = require.context("", true, /^((?!Lazy\.).)*$/i);
components.keys().forEach((path) => {
  const componentName = path.match(/\w+(?=(\.vue))/);
  if (componentName) {
    Vue.component(componentName[0], components(path).default);
  }
});

const lazyComponents = require.context("", true, /.*Lazy\..*/i, "lazy").keys();
lazyComponents.forEach((path) => {
  const componentName = path.match(/\w+(?=(\.vue))/);
  if (componentName) {
    Vue.component(componentpathName[0], () =>
      import(/* webpackPreload: true */ `${path}`)
    );
  }
}); 

// import LoadMoreComponent from "./LoadMoreComponent.vue";

// Vue.use('LoadMoreComponent', LoadMoreComponent);