<template>
  <progress :value="computedval"></progress>
</template>

<script>

export default{
  data: function() {
    return {
      computedval: this.progresscompleted,
      didScroll: false
    }
  },
  props: {
    progresscompleted: {
      type: Number,
      default: 0
    }
  },
  mounted() { 
    //console.log(this.progresscompleted);

    let _this = this

    window.addEventListener('scroll', function() {
       _this.didScroll = true
    })

    setInterval(function() {
      if (_this.didScroll) {
          _this.updateProgressBar();
          //_this.handleScroll();
          _this.didScroll = false;
      }
    }, 100)

  },
  methods: {
    updateProgressBar() {
  
      let _this = this

      let scrollTop = (document.documentElement["scrollTop"] || document.body["scrollTop"])

      let scrollBottom = document.getElementById("page_content").clientHeight - document.documentElement.clientHeight + document.getElementById("main-header").clientHeight

      let scrollPercent = scrollTop / scrollBottom

      if (scrollPercent > 1) {
        this.computedval  = 1
      } else if (scrollPercent < 0) {
        this.computedval  = 0
      } else {
        this.computedval  = scrollPercent
      }

    },
    /*handleScroll() {
      console.log("in handleScroll")
    }*/
  }
}
</script>