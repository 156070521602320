var bp_lg = '1500';
var bp_md = '1220';
var bp_sm = '800';
$(document).ready(function() {
  fixedSidebar.init();
});

var fixedSidebar = {
  didScroll: false,
  isFixed: false,
  toggleFix: function(st) {
    var _this = this;

    if ( Modernizr.mq("screen and (max-width:" + bp_md + "px)") ) { return; }

    var headerHeight = $('#main-header').length > 0 ? $('#main-header').height() : 0;
    var offset = _this.offset - headerHeight - _this.buffer;

    var stopOffset = _this.stopOffset - headerHeight;

    // Fixed
    if (st > offset && st <= stopOffset) {
      // Navbar should be fixed
      _this.el.addClass('fixed').removeClass('bottom');
      // no transition the first time gets fixed
      var transition = !_this.isFixed ? 'none' : '' ;
      _this.el.css({
        'right': _this.right,
        'top': headerHeight + _this.buffer,
        'transition': transition
      });
      _this.isFixed = true;
    }
    // Absolute at the bottom
    else if (st > stopOffset) {
        _this.el.removeClass('fixed').addClass('bottom');
        _this.el.css('right','0');
        _this.el.css('top', stopOffset - offset);
        _this.isFixed = false;
    }
    // Not fixed: default position (top)
    else {
      _this.el.css({
        'right': '',
        'top': '',
        'transition': ''
      });
      _this.el.removeClass('fixed');
      _this.isFixed = false;
    }
  },
  init: function () {
    var _this = this;

    _this.container = $('#page-sidebar-container');
    if (!_this.container.length) {
      return;
    }

    _this.el = _this.container.find('#page-sidebar');
    _this.offset = _this.container.offset().top;
    _this.buffer = 20; // space between header and panel
    _this.stopEl = $('#sidebar-anchor-stop');

    if ( Modernizr.mq("screen and (max-width:" + bp_lg + "px)") ) { _this.buffer = 10; }

    if ( $('#page-sidebar-container').length < 1 || $('#page-sidebar').length < 1 || $('#sidebar-anchor-stop').length < 1) { return; }

    _this.stopOffset = _this.stopEl.offset().top - _this.el.outerHeight() - _this.buffer; // does not include header height

    _this.right = $(window).width() - _this.container.offset().left - _this.container.width();

    // ===== Toggle Fixed State
    var st = $(window).scrollTop(); // int
    _this.toggleFix(st);

    // === SCROLL
    $(window).scroll(function(event){
      _this.didScroll = true;
    });

    setInterval(function() {
      if (_this.didScroll) {
        var st = $(window).scrollTop(); // int
        _this.toggleFix(st);
        _this.didScroll = false;
      }
    }, 50);

    // === RESIZE
    $(window).resize(function(event){
      if ( Modernizr.mq("screen and (max-width:" + bp_md + "px)") ) {

        // Fixed panel
        _this.el.css({
          'right': '',
          'top': '',
          'transition': ''
        });
        _this.el.removeClass('fixed');
        _this.isFixed = false;

      } else {

        // Fixed panel
        _this.offset = _this.container.offset().top;
        _this.stopOffset = _this.stopEl.offset().top - _this.el.outerHeight() - _this.buffer; // does not include header height
        _this.right = $(window).width() - _this.container.offset().left - _this.container.width();
      }
    });

  },
}