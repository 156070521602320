<template>
  <div :class="containerClass">
    <div
      v-show="isCollapsable"
      class="toggle-search"
      v-on:click="toggleCollapsableSearch($event)"
    >
      <svg class="icon"><use xlink:href="/dist/img/icon.svg#search" /></svg>
    </div>
    <autocomplete
      id="searchbox"
      url="/api/search"
      anchor="title"
      label="blogIntro"
      :placeholder="placeholder"
      :min="1"
      :on-select="itemSelected"
      :on-input="onInput"
      :on-show="onShow"
      :on-hide="onHide"
      :process="processJsonData"
      ref="myautocomplete"
    >
    </autocomplete>
    <span v-show="isNoResultsVisible" v-html="resultsMessage"></span>
  </div>
</template>

<script>
//https://github.com/nystudio107/vue2-autocomplete

import Autocomplete from "vue2-autocomplete-js";
import "vue2-autocomplete-js/dist/style/vue2-autocomplete.css";

export default {
  components: {
    Autocomplete,
  },
  props: {
    isCollapsable: { type: Boolean, default: false },
  },
  data() {
    return {
      menuOpen: false,
      isNoResultsVisible: false,
      noResultsMessage: "No results found for ",
      inputtedVal: "",
      containerClass: this.isCollapsable
        ? "collapsable-search"
        : "default-search",
      isExpanded: !this.isCollapsable,
      placeholder: this.isCollapsable ? "Search" : "",
    };
  },
  computed: {
    // a computed getter
    resultsMessage: function () {
      // `this` points to the vm instance

      //console.log(this.$refs)
      return (
        this.noResultsMessage + '<strong>"' + this.inputtedVal + '"</strong>'
      );
    },
  },
  methods: {
    prerenderLink: function (e) {
      var head = document.getElementsByTagName("head")[0];
      var refs = head.childNodes;
      ref = refs[refs.length - 1];

      var elements = head.getElementsByTagName("link");
      Array.prototype.forEach.call(elements, function (el, i) {
        if ("rel" in el && el.rel === "prerender")
          el.parentNode.removeChild(el);
      });

      var prerenderTag = document.createElement("link");
      prerenderTag.rel = "prerender";
      prerenderTag.href = e.currentTarget.href;
      ref.parentNode.insertBefore(prerenderTag, ref);
    },
    toggle: function () {
      //doesn't ever seem to be called
      console.log("toggle");
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        setTimeout(function () {
          var el = document.getElementById("searchbox");
          var etype = "click";
          if (el.fireEvent) {
            el.fireEvent("on" + etype);
          } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
          }
        }, 100);
      }
    },
    itemSelected: function (data) {
      /*if (ga !== undefined) {
					ga("send", "pageview", data.searchUrl); // sends /search/q=term to GA
				}*/
      window.location.href = data.url;
    },
    onInput: function (data) {
      this.inputtedVal = data;
      console.log("onInput");
      if (data == "") {
        this.isNoResultsVisible = false;

        const ac = this.$refs.ac;
      }
    },
    onShow: function () {
      console.log("onShow");
    },
    onHide: function () {
      console.log("onHide");
    },
    processJsonData: function (json) {
      let returnedData = false;
      for (var key in json.data) {
        if (json.data.hasOwnProperty(key)) returnedData = true;
      }

      if (returnedData) {
        this.isNoResultsVisible = false;
        return json.data;
      } else {
        this.isNoResultsVisible = !this.isCollapsable; // true if not collapsable search
        return false;
      }
    },
    toggleCollapsableSearch: function (e) {
      var btn = e.target;
      if (e.target.tagName == "svg") {
        btn = e.target.parentElement;
      } else if (e.target.tagName == "use") {
        btn = e.target.parentElement.parentElement;
      }
      var wrapper = btn.parentElement;
      if (this.isExpanded) {
        wrapper.classList.remove("expanded");
      } else {
        wrapper.classList.add("expanded");
      }
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {
    var el = document.getElementById("searchbox");
    el.addEventListener("click", function () {
      var el = document.getElementById("searchbox");
      var etype = "touchstart";
      if (el.fireEvent) {
        el.fireEvent("on" + etype);
      } else {
        var evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    });
    el.addEventListener("touchstart", function () {
      var el = document.getElementById("searchbox");
      var e = null;
      el.focus();
      //this.$refs.searchInput.focus(e);
    });
  },
};
</script>
