<template>
    <div>
        <div class="container">
            <nav class="listing-filters">
                <a v-for="status in statuses" href="javascript:void(0);" v-on:click="statusFilter(status)" :class="isActiveFilter(status)">
                    {{ status }}
                </a>
            </nav>
        </div>

        <p v-if="isEmpty"class="text-center">No Projects match your criteria.</p>
        <div class="featured-projects">
            <project-card-component v-for="(project, index) in projects" v-bind:key="project.id" :project="project" :index="index + 1"></project-card-component>
            <load-more-component v-if="showLoadMore" text="Load More Projects" v-on:next="nextPage()"></load-more-component>
        </div>
    </div>
</template>

<script>
import ProjectCardComponent from '../components/ProjectCardComponent.vue';
import LoadMoreComponent from '../components/LoadMoreComponent.vue';

export default {
    components: {
        ProjectCardComponent
    },
    data: function() {
        return {
            projects: [],
            page: 1,
            perPage: 2,
            showLoadMore: true,
            statuses: ['All','Current','Completed','Upcoming'],
            selectedStatus: 'All',
            isEmpty: false
        };
    },
    mounted() {
        this.getProjects();
    },
    methods: {
        getProjects: function(reset) {
            this.isEmpty = false;

            if(reset) {
                this.projects = [];
            }

            this.$http.get('/api/projects.json?pp=' + this.perPage + '&pg=' + this.page + '&status=' + this.selectedStatus).then(function(data) {
                this.projects = this.projects.concat(data.body.data);
                this.showLoadMore = this.page >= data.body.meta.pagination.total_pages ? false : true;

                if(!this.projects.length) {
                    this.isEmpty = true;
                }

                this.page++;
            });
        },
        nextPage: function() {
            this.getProjects();
        },
        statusFilter: function(value) {
            this.selectedStatus = value;
            this.page = 1;

            this.getProjects(true);
        },
        isActiveFilter: function(value) {
            return this.selectedStatus == value ? 'active' : false;
        }
    }
}
</script>
