window.Popper = require("popper.js").default;
import Vue from "vue";

import "./components";
import Modules from "./modules";
import "./page.js";
import "./images.js"
import "../scss/style.scss";
import "../scss/demo/_floorplan.scss";

// import InstantSearch from 'vue-instantsearch'; // Required for some of the search components in dev-components/
import VueResource from "vue-resource";

const main = async () => {
  Vue.use(VueResource);  
  new Vue({
    el: "#app",
  });
}

main().then(() => {
  Modules();
})