<template>
    <div class="">
        <button id="trigger-mobile-menu"
                type="button"
                class="btn-style-none hamburger-menu-btn"
                role="button"
                aria-label="Toggle Navigation"
                v-on:click="toggleMenu"
                v-bind:class="{ off: isOpen }"
                >{{ btn_text }}</span>
        </button>

         <div v-if=show_cta id="mobile-cta" class="off">
            <button id="mobile-cta-close"><svg class="icon"><use xlink:href="/dist/img/icon.svg#close" /></svg></button>
            <a class="cta-btn" :href="start_project_url">{{ start_project_text }}</a>
        </div>

        <!-- <a href="/start-project" id="mobile-cta" class="cta-btn">Start a Project</a> -->


        <transition name="mobile-menu-fade">
            <div id="mobile-menu" v-if="isOpen">
                <div class="menu-wrapper">
                    <nav class="nav-menu">
                        <a :href="item.uri" v-for="(item, index) in revisedMenu">{{item.title}}</a>
                    </nav>
                    <div v-if=show_languages class="site-languages-list">
                        <a :class="item.active" :href="item.uri" v-for="(item, index) in revisedCountryMenu">{{item.name}} <img class="flag" :src="item.flaguri" :alt="item.name"></a>
                        <!--<a href="#" ><img class="flag" src="/dist/img/icn-flag-au.svg" alt="Australia"></a>
                        <a href="#"><img class="flag" src="/dist/img/icn-flag-cn.svg" alt="China"></a>
                        <a href="#"><img class="flag" src="/dist/img/icn-flag-usa.svg" alt="USA"></a>-->
                    </div>
                    <div class="social-inline">
                        <h5 class="social-title">{{ connect_text }}</h5>
                        <div class="social-links dark-grey">
                            <a v-if="fb_url" :href="fb_url" target="_blank" aria-label="Follow us on Facebook">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#facebook" /></svg>
                            </a>
                            <a v-if="twitter_url" :href="twitter_url" target="_blank" aria-label="Follow us on Twitter">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#twitter" /></svg>
                            </a>
                            <a v-if="insta_url" :href="insta_url" target="_blank" aria-label="Follow us on Instagram">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#instagram" /></svg>
                            </a>
                            <a v-if="linkedin_url" :href="linkedin_url" target="_blank" aria-label="Follow us on LinkedIn">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#linkedin" /></svg>
                            </a>
                            <a v-if="pinterest_url" :href="pinterest_url" target="_blank" aria-label="Follow us on Pinterest">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#pinterest" /></svg>
                            </a>
                            <a v-if="youtube_url" :href="youtube_url" target="_blank" aria-label="Follow us on Youtube">
                                <svg class="icon"><use xlink:href="/dist/img/icon.svg#youtube" /></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            fb_url: {type: String, default: null},
            twitter_url: {type: String, default: null},
            insta_url: {type: String, default: null},
            linkedin_url: {type: String, default: null},
            pinterest_url: {type: String, default: null},
            youtube_url: {type: String, default: null},
            classes: {type: String, default: null},
            show_cta: {type: Number, default: 1},
            show_languages: {type: Number, default: 0},
            items: {type: Array, default: null},
            countryitems: {type: Array, default: null},
            btn_text: {type: String, default: null},
            cur_language: {type: String, default: null},
            connect_text: {type: String, default: null},
            start_project_text: {type: String, default: null},
            start_project_url: {type: String, default: null},
            contact_url: {type: String, default: null}
        },
        data: function() {
            return {
                isOpen: false,
                //btnText: 'Menu'
            }
        },
        computed: {
            revisedMenu: function () {
                let menu = this.items
                let contactText = 'Contact'

                if(this.cur_language == 'zh-Hans') contactText = '联系我们'

                if(this.items != null) menu.push({title: contactText, uri: this.contact_url})
                else {
                    //menu is null
                    var blankMenu = new Array()
                    blankMenu.push({title: "Contact", uri: this.contact_url})
                    return blankMenu
                }
                return menu
            },
            revisedCountryMenu: function () {
                let menu = this.countryitems
                return menu
            }
        },
        methods: {
            toggleMenu: function(e) {
                this.isOpen = !this.isOpen;

                if (this.isOpen == true) {
                    this.btnText = 'Close';
                    document.body.classList.add('menu-open');
                } else {
                    this.btnText = 'Menu';
                    document.body.classList.remove('menu-open');
                }
            },
            toggleBodyClass(addRemoveClass, className) {
                const el = document.body;

                if (addRemoveClass === 'addClass') {
                  el.classList.add(className);
                } else {
                  el.classList.remove(className);
                }
            },
        }
    }
</script>
