/*---- STORE CS, CSS, CA URL PARAMS IN COOKIE ------*/

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

/*---- GET CS, CSS, CA FROM URL FOR PARDOT ------*/

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS, "i");
  var results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

/*---- SET COOKIES ----*/

if (getParameterByName("cs") !== "") {
  setCookie("cs", getParameterByName("cs"), 30);
}
if (getParameterByName("css") !== "") {
  setCookie("css", getParameterByName("css"), 30);
}
if (getParameterByName("ca") !== "") {
  setCookie("ca", getParameterByName("ca"), 30);
}

/*---- SET HIDDEN FORM VALUES ------*/

if (document.getElementById("cs") !== null) {
  //document.getElementById("form_cs").value = getParameterByName("cs"); // if only need to check 1 url
  document.getElementById("cs").value = getCookie("cs");
}
if (document.getElementById("css") !== null) {
  //document.getElementById("form_css").value = getParameterByName("css");
  document.getElementById("css").value = getCookie("css");
}
if (document.getElementById("ca") !== null) {
  //document.getElementById("form_ca").value = getParameterByName("ca");
  document.getElementById("ca").value = getCookie("ca");
}

// Debounce function https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// Use js to replace css vh on small touch devices
if (Modernizr.touchevents && Modernizr.mq("screen and (max-width:800px)")) {
  // vh on mobile: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
}

/*---- Cookies for mobile CTA ----*/
document.addEventListener("DOMContentLoaded", function (event) {
  var mobileCta = document.getElementById("mobile-cta");
  if (mobileCta) {
    if (!getCookie("mobile-cta-off")) {
      // show cta
      mobileCta.classList.remove("off");
    }

    // close popup on click on close icon

    var closeBtn = document.getElementById("mobile-cta-close");
    closeBtn.addEventListener("click", function (event) {
      // hide cta
      mobileCta.classList.add("off");
      // set cookie
      setCookie("mobile-cta-off", "1", 30);
    });
  }
});
/*---- End: Cookies for mobile CTA ----*/

/*---- Site Popup ----*/
var sitePopup = document.getElementById("site-popup");

if (sitePopup !== null) {
  document.addEventListener("DOMContentLoaded", function () {
    // reset popup cookie
    if (sitePopupReset == true) {
      eraseCookie("sitepopupshown");
    }

    // show popup after x sec.
    setTimeout(function () {
      if (!getCookie("sitepopupshown")) {
        // show popup
        sitePopup.classList.add("on");
        document.body.classList.add("overflow-hidden");

        // set cookie
        setCookie("sitepopupshown", "1", sitePopupExpiry);
      }
    }, sitePopupDelay * 1000);

    // close popup on click on close icon
    document.addEventListener(
      "click",
      function (event) {
        // Return if target is not overlay nor close button or inside it.
        if (
          !event.target.matches("#site-popup-overlay") &&
          !event.target.matches("#site-popup-close") &&
          !event.target.closest("#site-popup-close")
        )
          return;
        event.preventDefault();

        sitePopup.classList.remove("on");
        document.body.classList.remove("overflow-hidden");
      },
      false
    );
  });
}

/*---- End: Site Popup ----*/

/*---- lazysizes (lazyload for images) ------*/

require("lazysizes");

document.addEventListener("lazybeforeunveil", function (e) {
  var bg = e.target.getAttribute("data-bg");
  if (bg) {
    e.target.style.backgroundImage = "url(" + bg + ")";
  }
});

/*---- end: lazysizes ------*/

/*---- Intersection Observer------*/

require("intersection-observer"); // polyfill for Safari and IE

document.addEventListener("DOMContentLoaded", function () {
  var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              //let's add some logic in here to deal with mobile v desktop

              if (window.matchMedia("(max-width: 400px)").matches) {
                if (videoSource.getAttribute("data-srcthumbnail")) {
                  videoSource.src = videoSource.dataset.srcthumbnail;
                } else {
                  videoSource.src = videoSource.dataset.src;
                }
              } else {
                videoSource.src = videoSource.dataset.src;
              }
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }

  // === HOME Featured projects counter
  if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
      targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
      padString = String(typeof padString !== "undefined" ? padString : " ");
      if (this.length >= targetLength) {
        return String(this);
      } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
          padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(this);
      }
    };
  }

  // Add leading zero before number
  function numWithLeadingZero(num, width) {
    return num.toString().padStart(width, 0);
  }

  var projectsCounter = document.getElementById("featured-projects-counter"); // has off class by default

  if (projectsCounter) {
    var counterCurrent = projectsCounter.getElementsByClassName("current")[0];

    var projectSections = [].slice.call(
      document.querySelectorAll(".featured-project")
    );
    var projectSectionsFirst = projectSections[0];
    var projectSectionsLast = projectSections[projectSections.length - 1];
    var currentSection = null;

    if ("IntersectionObserver" in window) {
      var options = {
        rootMargin: "-50%",
      };

      var projectCounterObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (section) {
          // inside section
          if (section.isIntersecting) {
            currentSection = section;
            // update counter
            var index = projectSections.indexOf(section.target);
            counterCurrent.textContent = numWithLeadingZero(index + 1, 2);

            // show counter when inside first or last section
            if (
              section.target == projectSectionsFirst ||
              section.target == projectSectionsLast
            ) {
              projectsCounter.classList.remove("off");
            }
          }
          // before 1st section: hide counter
          else if (
            section.target == projectSectionsFirst &&
            (currentSection == null ||
              (currentSection != null &&
                currentSection.target == projectSectionsFirst))
          ) {
            currentSection == null;
            projectsCounter.classList.add("off");
          }
          // after 3d section: hide counter
          else if (
            section.target == projectSectionsLast &&
            currentSection != null &&
            currentSection.target == projectSectionsLast
          ) {
            currentSection == null;
            projectsCounter.classList.add("off");
          }
        });
      },
      options);

      projectSections.forEach(function (section) {
        projectCounterObserver.observe(section);
      });
    }
  }
  // End HOME Featured projects counter

  // === Stacker nav active state
  var stackerNav = document.getElementById("stacker-nav-menu");

  if (stackerNav) {
    var stackerSections = [].slice.call(
      document.querySelectorAll(".stacker-section")
    );
    var stackerMenuItems = [].slice.call(stackerNav.querySelectorAll("a"));

    if ("IntersectionObserver" in window) {
      var options = {
        rootMargin: "-50%",
      };

      var stackerNavObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (section) {
          // inside section: update active menu item
          if (section.isIntersecting) {
            stackerMenuItems.forEach(function (item) {
              item.classList.remove("active");
            });
            var index = stackerSections.indexOf(section.target);
            stackerMenuItems[index].classList.add("active");
          }
        });
      },
      options);

      stackerSections.forEach(function (section) {
        stackerNavObserver.observe(section);
      });
    }
  }
});

/*---- end: Intersection Observer------*/

/*---- Smooth Scroll (jquery) ------*/
$(document).ready(function () {
  $("a.smoothscroll")
    .off("click")
    .on("click", function (e) {
      e.preventDefault();
      var top = $(this.hash).offset().top;
      $("body,html").stop().animate(
        {
          scrollTop: top,
        },
        600
      );
    });
});
/*---- End: Smooth Scroll (jquery) ------*/

/*---- Dropdown logic (jquery) ------*/
$(document).ready(function () {
  $("#main-header .dropdown")
    .on("mouseenter", function () {
      $(this).addClass("open");
      $("body").addClass("dropdown-open");
    })
    .on("mouseleave", function () {
      $(this).removeClass("open");
      $("body").removeClass("dropdown-open");
    });

  $("#main-header .dropdown .close-btn").on("click", function (e) {
    $(this).parents(".dropdown").removeClass("open");
    $("body").removeClass("dropdown-open");
  });

  $("#main-header .dropdown .close-dd").on("click", function (e) {
    $(this).parents(".dropdown").removeClass("open");
    $("body").removeClass("dropdown-open");
  });
});
/*---- end: Dropdown logic ------*/

/*---- Forms (jquery) ------*/
$(document).ready(function () {
  // === Toggle focus on parent (where border is)
  $(".form-control").bind("focus blur", function () {
    $(this).closest(".form-group").toggleClass("focus");
    $(this).parents(".inline-form").toggleClass("focus");
  });

  // === Toggle filled class
  $(".form-control").on("change", function () {
    if (this.value) {
      $(this).closest(".form-group").addClass("filled");
      $(this).parents(".inline-form").addClass("filled");
    } else {
      $(this).closest(".form-group").removeClass("filled");
      $(this).parents(".inline-form").removeClass("filled");
    }
  });

  // === Toggle checked class
  $(".form-group.checkbox-group input").on("change", function () {
    if ($(this).prop("checked") == true) {
      $(this).closest(".form-group").addClass("checked");
    } else {
      $(this).closest(".form-group").removeClass("checked");
    }
  });

  // ==== File upload field
  var fileUpload = $('.fileUpload input[type="file"]');
  if (fileUpload.length > 0) {
    // on load check value
    fileUpload.each(function () {
      var input = $(this);
      if (input.val().length > 0) {
        var fileName = input.val().split("\\").pop();
        inputFileShowFilename(input, fileName);
      }
    });

    fileUpload.on("change", function () {
      var input = $(this);
      var name = input.attr("name");
      var filePath = input.val();
      var fileName = filePath.split("\\").pop();
      var form = input.parents("form");

      // if validation has already occurred,
      // validate this input on change
      if (form.hasClass("validated")) {
        parsleyValidateInputFile(input);
      }

      inputFileShowFilename(input, fileName);
    });
  }
});
function inputFileShowFilename(input, fileName) {
  // file upload container
  var fileUpload = input.parent(".btn-upload").parent(".fileUpload");
  // where uploaded files append
  var ul = fileUpload.find(".files ul");
  // uploaded files template
  var tpl1 = $("#uploadedFileTpl .uploadedFile");
  // uploaded files
  var uploaded = "";

  var tpl = [
    '<li class="uploadedFile loading">',
    '<div class="name">' + fileName + "</div>",
    '<div class="pull-right">',
    '<div class="remove"><svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" x="0" y="0"><g fill="none" stroke="currentColor" stroke-linecap="round"><path d="M19 1L1.3 18.7M18.7 19L1 1.3"/></g></svg></div>',
    "</div>",
    "</li>",
  ].join("");

  var form = input.parents("form");

  // Append the file name to uploaded template
  // tpl.find('.name').text(fileName);

  // Add the HTML to the UL element
  ul.append(tpl);
  uploaded = ul.find(".uploadedFile").fadeIn(100);
  fileUpload.addClass("uploaded");

  // Listen for clicks on the cancel icon
  uploaded.find(".remove").click(function () {
    uploaded.fadeOut(100, function () {
      uploaded.remove();

      fileUpload.removeClass("uploaded");
      // clear input value
      input.val("");

      // if validation has already occurred,
      // validate input when file is removed
      if (form.hasClass("validated")) {
        parsleyValidateInputFile(input);
      }
    });
  });
}
/*---- End: Forms (jquery) ------*/

// === Revel number
$(document).ready(function () {
  $(".reveal-number")
    .off()
    .on("click", function (e) {
      if ($(this).hasClass("is-min")) {
        e.preventDefault();

        // tracking here
        if (typeof gtag === "function") {
          gtag("event", "click", {
            event_category: "Team Reveal Number",
            event_label: $(this).attr("data-formatted-number"),
          });
        }

        $(this).html($(this).attr("data-number"));
        $(this).attr("href", "tel:" + $(this).attr("data-formatted-number"));

        $(this).removeClass("is-min");
        $(this).blur(); // remove focus
      }
    });
});

/*---- FORM SPINNER ------*/
// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
document.addEventListener("DOMContentLoaded", function (event) {
  var forms = document.querySelectorAll("FORM");
  if (forms) {
    forms.forEach(function (form) {
      var btn = form.getElementsByClassName("form-btn")[0];
      if (!btn) {
        return;
      }

      if (form.addEventListener) {
        form.addEventListener("submit", callback, false); //Modern browsers
      } else if (form.attachEvent) {
        form.attachEvent("onsubmit", callback); //Old IE
      }

      function callback(evt) {
        btn.classList.add("loading");
      }
    }); // end forEach
  } // end if
}); // end DOMContentLoaded
/*---- End: FORM SPINNER ------*/

// Share Reveal Popup (used on project page)
$(document).ready(function () {
  if (
    $("#share-reveal-overlay").length > 0 &&
    $(".share-reveal.reveal-popup").length > 0
  ) {
    $(".share-reveal.reveal-popup .share-label").on("click", function () {
      var popup = $(this).parents(".reveal-popup").find(".popup");
      if (popup.hasClass("on")) {
        closeShareRevealPopup();
      } else {
        openShareRevealPopup();
      }
    });
    $("#share-reveal-overlay").on("click", closeShareRevealPopup);
    $(".share-reveal .popup .close").on("click", closeShareRevealPopup);

    function closeShareRevealPopup() {
      $(".share-reveal.reveal-popup .popup").removeClass("on");
      $("#share-reveal-overlay").removeClass("on");
    }
    function openShareRevealPopup() {
      $(".share-reveal.reveal-popup .popup").addClass("on");
      $("#share-reveal-overlay").addClass("on");
    }
  }
});

document.addEventListener("DOMContentLoaded", function (event) {
  var header = document.getElementById("main-header");
  var lastScrollTop = 0;
  updateHeader();
  window.addEventListener(
    "scroll",
    function () {
      updateHeader();
    },
    false
  );

  function updateHeader() {
    // Show/hide stacker nav on scroll up/down
    if (header.classList.contains("has-stacker")) {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      // going down
      if (st > lastScrollTop) {
        header.classList.add("stacker-on");
      }
      // going up
      else {
        header.classList.remove("stacker-on");
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }

    // Change header style if 5px from top (small logo, etc)
    if (window.pageYOffset > 5) {
      header.classList.add("min");
      document.body.classList.add("header-min");
    } else {
      header.classList.remove("min");
      document.body.classList.remove("header-min");
    }
  }
});

try {
  window.$ = window.jQuery = require("jquery");
  window.slick = require("slick-carousel");
  window.flickity = require("flickity/dist/flickity.pkgd.js");

  require("objectFitPolyfill");
  require("bootstrap");
  require("slick-carousel/slick/slick.css");
  require("slick-carousel/slick/slick-theme.css");
  require("flickity/css/flickity.css");
} catch (e) {}
