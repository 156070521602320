<template>
  <div>
    <div style="display:block;">
      <h3>Simple map</h3>
      <p>First marker is placed at {{ withPopup.lat }}, {{ withPopup.lng }}</p>
      <p>Center is at {{ currentCenter }} and the zoom is: {{ currentZoom }}</p>
      <button @click="showLongText">Toggle long popup</button>
    </div>
    <div style="width:100%; height: 400px;"
	    <l-map
	      :zoom="zoom"
	      :center="center"
	      :options="mapOptions"
	      style="width: 100%;height: 400px"
	      @update:center="centerUpdate"
	      @update:zoom="zoomUpdate"
	    >
	      <l-tile-layer :url="url" :attribution="attribution" />
	      <l-marker :lat-lng="withPopup">
	        <l-popup>
	          <div @click="innerClick">
	            I am a popup
	            <p v-show="showParagraph">
	              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
	              sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
	              Donec finibus semper metus id malesuada.
	            </p>
	          </div>
	        </l-popup>
	      </l-marker>
	      <l-marker :lat-lng="withTooltip">
	        <l-tooltip :options="{ permanent: false, interactive: true }">
	          <div @click="innerClick">
	            I am a tooltip
	            <p v-show="showParagraph">
	              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
	              sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
	              Donec finibus semper metus id malesuada.
	            </p>
	          </div>
	        </l-tooltip>
	      </l-marker>
	    </l-map>
	</div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip
  },
  props: {
    rows: {type: String, default: []}, 
  },
  data() {
    return {
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      }
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    }
  }
};
</script>
