require('waypoints/lib/noframework.waypoints.min');

module.exports = () => {
  // Home banner scroll symbol
  const element = document.getElementById('banner-scroll');
  if (!element) {
    return;
  }

  new Waypoint({
    element,
    handler: function(direction) {
      if ( direction == 'down' ) {
        this.element.classList.add('hover');
      } else {
        this.element.classList.remove('hover');
      }
    },
    offset: function() {
      var offset = window.innerHeight - this.element.clientHeight - 50; // 50px from bottom of viewport
      return offset;
    }
  });
}
