import "./demo.js";
import "./modernizr.js";
import "./video.js";
import "./infinite-slider.js";
import "./img-mosaic.js";
import "./galleryModal.js";
import "./gallery.js";
import "./testimonials.js";
import "./projectHeroSlider.js";
import "./fixedSidebar.js";
import "./projectAgent.js";
import "./mapContact.js";
import "./mapProjects.js";
import "./mapProjectLots.js";
import "./mapProject.js";
import Waypoints from "./waypoints.js";
import "./favourites.js"; 

export default () => {
    Waypoints();
}