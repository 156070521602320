<template>
    <article v-if="fields" :id="articleId" class="featured-project">
        <a :href="url">
            <div v-if="fields.projectImage" class="featured-project-bg-img bg-img-cover lazyload" :data-bg="fields.projectImage"></div>
            <div v-else class="featured-project-bg-img bg-img-cover img-empty"></div>
            <div class="container">
                <div class="featured-project-info">
                    <h4 v-if="fields.projectLocation" class="subheading">{{ fields.projectLocation }}</h4>
                    <h1 v-html="heading" class="heading"></h1>
                    <div v-if="fields.intro" class="description">
                        <p class="lead">{{ fields.intro }}</p>
                    </div>
                    <span class="text-link white" href="#">View Project</span>
                </div>
            </div>
        </a>
    </article>
</template>

<script>
export default {
    props: {
        project: {type: Object},
        index: {type: Number}
    },
    data: function() {
        return {
            fields: this.project
        };
    },
    computed: {
        articleId: function () {
            return 'project-' + this.index;
        },
        heading: function () {
            return this.fields.displayedHeading ? this.fields.displayedHeading : this.fields.title;
        },
        url: function () {
            return '/' + this.fields.uri;
        }
    }
}
</script>
