/*
 * - Agent Slider
 * - Agent Popup (populate fields)
 *
*/

import 'slick-carousel';
var arrow_right = '<svg id="arrow_right" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M0.5 10L18.8 10"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M14.8 6L18.8 10 14.8 14"/></svg>';
var arrow_left= '<svg id="arrow_left" class="icon" viewBox="0 0 20 20" x="0" y="0"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M19.6,10H1.4"/><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-miterlimit="10" d="M5.3,14l-4-4l4-4"/></svg>';
var bp_sm = '800';

$(document).ready(function() {
  initAgentSliders();
  agentModal();
});


// ========= AGENT SLIDER 
// Using Slick.js

var agentSlider_instances = [];

var initAgentSliders = function() {

  var agentSlider = $('.agent-slider-container');

  if ( agentSlider.length ) {
    agentSlider.each(function(){
      agentSlider_instances.push(initAgentSlider($(this)));
    })

  }
}
var destroyAgentSliders = function() {
  var instance;
  while (agentSlider_instances.length) {
    instance = agentSlider_instances.shift();
    instance.destroy();
  }
}

var initAgentSlider = function(container) {

  var agentSlider = {

    initialized: false,
    container: null,
    slider: null,
    options: {
      infinite: true,
      autoplay:false, // start when fixed nav displays
      fade: true,
      speed: 300,
      dots: false,
      arrows: true,
      appendArrows: container.find('.slider-arrows'),
      prevArrow: '<button type="button" class="arrow-btn arrow-prev" aria-label="Previous">'+arrow_left+'</button>',
      nextArrow: '<button type="button" class="arrow-btn arrow-next" aria-label="Next">'+arrow_right+'</button>',
      lazyLoad: 'ondemand',
      draggable: false,
      rows: 0
    },

    draw: function(){
      var _this = this;
      _this.el =  _this.slider.slick(_this.options);
    },

    destroy: function() {
      var _this = this;
      if ( !_this.el ) return;
      _this.el.slick('unslick');
      _this.initialized = false;
      _this.container.addClass('loading');
    },

    reset: function() {
      var _this = this;
      _this.destroy();
      _this.draw();
    },
    init: function(container) {
      var _this = this;

      if (container === undefined || container.length < 1) return;

      _this.container = container;
      _this.slider = _this.container.find('.agent-slider');

      if (_this.slider.find('.agent-item').length < 2 ) { _this.container.removeClass('loading')}

      // === On Init
      _this.slider.on('init', function (slick) {
        _this.container.removeClass('loading');
        _this.initialized = true;
      });


      // === Before Change
      _this.slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

        var nextSlide = _this.slider.find('.slick-slide').eq(nextSlide);

        // Update form if slider is inside agent-form-container.
        if (container.parents('.agent-form-container').length > 0 ) {
          var parent = _this.container.parents('.agent-form-container');

          var agentId = nextSlide.find('.agent-info').attr('data-agent-id');
          var projectId = nextSlide.find('.agent-info').attr('data-project-id');
          var projectName = nextSlide.find('.agent-info').attr('data-project-name');
          var agentName = nextSlide.find('.agent-info').attr('data-agent-name');

          if ( agentId ) {
            parent.find('input[name="agentId"]').val(agentId);
          }
          if ( agentName ) {
            parent.find('.enquire-agent-name').html(agentName);
          }
          if ( projectId ) {
            parent.find('input[name="projectId"]').val(projectId);
          }
          if ( projectName ) {
            parent.find('input[name="projectName"]').val(projectName);
          }
        }
      })
        
      // === Draw slider
      _this.draw();

      $(window).on('resize', function () { 
        if ( ! _this.initialized ) {
          _this.draw();
        }
      })

      return _this;
    }
  }

  return agentSlider.init(container);
  
}

// Popuplate fields when opening modal

function agentModal() {
  var modal = $('#enquire_agent_modal');
  modal.on('show.bs.modal', function (e) {

    // Update Data
    var agentId = parseInt($(e.relatedTarget).attr('data-agent-id'));
    var projectId = $(e.relatedTarget).attr('data-project-id');
    var projectName = $(e.relatedTarget).attr('data-project-name');
    var agentName = $(e.relatedTarget).attr('data-agent-name');

    if ( agentId ) {
      modal.find('input[name="agentId"]').val(agentId);
    }
    if ( agentName ) {
      modal.find('.enquire-agent-name').html(agentName);
    }
    if ( projectId ) {
      modal.find('input[name="projectId"]').val(projectId);
    }
    if ( projectName ) {
      modal.find('input[name="projectName"]').val(projectName);
    }

    // Go to slide 
    var thisSlide = modal.find('.agent-item[data-agent-id="'+agentId+'"]');
    var slideId = thisSlide.attr('data-slick-index');

    var modalSlider = modal.find('.agent-slider');
    if ( modalSlider.length > 0 && modalSlider.hasClass('slick-initialized') ) {
        modalSlider.slick('slickGoTo',slideId);
    }
  })
}



