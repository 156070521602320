<template>
    <div class="load-more text-center">
        <button class="primary-btn btn-has-spinner" v-on:click="loadMore()">
            <span class="text">{{ text }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        text: { type: String },
    },
    methods: {
        loadMore: function () {
            this.$emit('next');
        }
    }
}
</script>
