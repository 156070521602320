// Using Flickity

var bp_sm = '800';

$(document).ready(function() {
  initInfiniteSliders();

  window.flickity.prototype.setTranslateX = function( x, is3d ) {
	var offset =  (this.cells[0].size.width * this.cellAlign) - this.cells[0].size.paddingLeft;
	if ( Modernizr.mq("screen and (max-width:" + bp_sm + "px)")) { offset = 0; }
	x += this.cursorPosition + offset;
	
	// reverse if right-to-left and using transform
	x = this.options.rightToLeft ? -x : x;
	var translateX = this.getPositionValue( x );
  
	// use 3D tranforms for hardware acceleration on iOS
	// but use 2D when settled, for better font-rendering
	this.slider.style.transform = is3d ?
	  'translate3d(' + translateX + ',0,0)' : 'translateX(' + translateX + ')';
  };
  
  
  window.flickity.prototype.updateCellAlign = function(cellAlign) {
	  this.options.cellAlign = cellAlign;
	this.cellAlign = cellAlign;
  };
  
});




// ======== INFINITE SLIDER (news & work cards)

var infiniteSlider_instances = [];

var initInfiniteSliders = function() {

	var infiniteSlider = $('.infinite-slider-container');

	if ( infiniteSlider.length ) {
		infiniteSlider.each(function(){
			infiniteSlider_instances.push(initInfiniteSlider($(this)));
		})

	}
}
var destroyInfiniteSliders = function() {
	var instance;
	while (infiniteSlider_instances.length) {
		instance = infiniteSlider_instances.shift();
		instance.destroy();
	}
}

var initInfiniteSlider = function(container) {

	var infiniteSlider = {
		initialized: false,
		container: null,
		slider: null,
		numberPerRowDesktop: 3,
		numberPerRowMobile: 1,

		draw: function(){
			var _this = this;

			_this.container.addClass('init');
			_this.container.removeClass('loading');

			var align = .5; // center
			if ( !Modernizr.mq("screen and (max-width:" + bp_sm + "px)")) { 
				var leftOffset = ( _this.container.innerWidth() -  _this.container.find('.container').width() ) / 2;
	  		var align = leftOffset / _this.container.innerWidth();
			}

			// dont init again if already init
			if ( _this.initialized == true ) { return; } 

			_this.slider.flickity({
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false, // looping
				groupCells: 1, // move items one by one
				lazyLoad: _this.numberPerRow,
				draggable: _this.draggable,
				cellAlign: align,
				arrowShape: 'M96.7 47.8h-88l13.6-19.3c.8-1.2.6-2.8-.6-3.6-1.2-.8-2.8-.6-3.6.6L1.4 49c-.6.9-.6 2.1 0 3L18 75.5c.5.7 1.3 1.1 2.1 1.1.5 0 1-.2 1.5-.5 1.2-.8 1.5-2.5.6-3.6L8.5 53h88.2c1.4 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6z'
			});
			_this.el = _this.slider;

			_this.initialized = true;
			_this.setActive(0);
			_this.updateCount(0);
			_this.setSettled(0);

			_this.el.on( 'select.flickity', function( event, index ) {
			  _this.items.removeClass('active');
			  _this.setActive(index);
			  _this.updateCount(index);
			});


			_this.el.on( 'settle.flickity', function( event, index ) {
			  _this.items.removeClass('settled');
			  _this.setSettled(index);
			});

			_this.el.on( 'staticClick.flickity', function( event, pointer, cellElement, cellIndex ) {
				event.preventDefault();
				if ( !$(cellElement).hasClass('active') ) {
					// prev or next cell
					if ( $(cellElement).hasClass('previous') ) {
						_this.el.flickity('previous');
					} else if ( $(cellElement).hasClass('next') ) {
						_this.el.flickity('next');
					}
				}
				else if ( $(event.target).hasClass('slider-item') || $(event.target).hasClass('infinite-slider-card') ) {
					// margin around <a>
				} else {
					// trigger click
					var url = $(cellElement).find('a').attr('href');
					location.href = url;
				}
			});

			// Prevent Link by default, manually trigger it on staticClick
			_this.items.on('click', function(e){
				e.preventDefault();
			})

		},

		
		setActive: function(selectedId) {
			var _this = this;
			var id = selectedId;
			_this.items.eq(id).addClass('active');

			var idBefore = id == 0 ? _this.totalSlide - 1 : id - 1;
			var idAfter = id == _this.totalSlide - 1 ? 0 : id + 1;

			var idAfter2 = id + 2;
			if ( id == _this.totalSlide - 2 ) {
				idAfter2 = 0;
			} else if ( id == _this.totalSlide - 1 ) {
				idAfter2 = 1;
			}

			var idPrev = idBefore;
			var idNext = idAfter;

			// 3 middle cards are highlighted: current + 2 after
			if (_this.numberPerRow > 1 ) {
				_this.items.eq(idAfter).addClass('active');
				_this.items.eq(idAfter2).addClass('active');
				idPrev = idBefore;
				idNext = idAfter2 == _this.totalSlide - 1 ? 0 : idAfter2 + 1;
			} 

			// 1 card per row
			_this.items.removeClass('previous');
			_this.items.removeClass('next');
			_this.items.eq(idPrev).addClass('previous');
			_this.items.eq(idNext).addClass('next');
		},

		// Settled are middle cards = able to click on them
		setSettled: function(selectedId) {
			var _this = this;
			var id = selectedId;
			_this.items.eq(id).addClass('settled');

			if (_this.numberPerRow > 1 ) {
				var idAfter = id == _this.totalSlide - 1 ? 0 : id + 1;

				var idAfter2 = id + 2;
				if ( id == _this.totalSlide - 2 ) {
					idAfter2 = 0;
				} else if ( id == _this.totalSlide - 1 ) {
					idAfter2 = 1;
				}
				_this.items.eq(idAfter).addClass('settled');
				_this.items.eq(idAfter2).addClass('settled');
			}

		},
		// by default selected is on the far left. We want to show count as if selected is on the far right
		updateCount: function(selectedId) {
			var _this = this;
			var offset = selectedId+_this.numberPerRow > _this.totalSlide ? _this.totalSlide : 0;
			var i = selectedId + _this.numberPerRow - offset;
			var num = ('0' + (i)).slice(-2);
			// var num = ('0' + (selectedId+1)).slice(-2);
      _this.container.find('.slider-count .current').html(num);
		},

		destroy: function() {
			var _this = this;
			if ( !_this.el ) return;

			_this.el.flickity('destroy');

			_this.container.addClass('loading');
			_this.container.removeClass('init');
			_this.initialized = false;
		},

		resize: function() {
			var _this = this;
			if ( !_this.el ) return;

			_this.el.flickity('resize');
		},

		setItemSize: function() {
			var _this = this;
			var wrapper_width = _this.container.find('.container').width(); // without padding
			var gutter = parseInt(_this.items.css('padding-right'), 10) * 2;
			if ( _this.numberPerRow > 1 ) {
				var w = ((wrapper_width - (gutter*2)) / _this.numberPerRow) + gutter;
			} else {
				var w = wrapper_width + gutter;
			}
			_this.items.css('width',w);
		},


		reset: function() {
			var _this = this;
			_this.destroy();
			_this.draw();
		},

		init: function(container) {
			var _this = this;

			if (container === undefined || container.length < 1) return;

			// === Variables
			_this.container = container;
			_this.slider = _this.container.find('.slider');
			_this.items = _this.container.find('.slider-item');

			if (Modernizr.mq("screen and (max-width:" + bp_sm + "px)")) {
				_this.numberPerRow = _this.numberPerRowMobile;
				_this.container.addClass('mobile-layout');
			} else {
				_this.numberPerRow = _this.numberPerRowDesktop;
				_this.container.addClass('desktop-layout');
				_this.setItemSize();
			}

			_this.draggable = true;
			if (_this.slider.find('.infinite-slider-card').length <= _this.numberPerRow) {
				_this.draggable = false; 
			} 

			_this.totalSlide = _this.items.length;

			_this.slides = _this.slider.html();

			// Do not draw if mobile slider on desktop
			if (_this.container.hasClass('mobile-slider') && !Modernizr.mq("screen and (max-width:" + bp_sm + "px)")) {
				_this.container.find('.slider').wrap('<div class="container"></div>');
				_this.container.removeClass('loading');
			} else {
				_this.draw();
			}

			$(window).on('resize',function() {

				if ( Modernizr.mq("screen and (max-width:" + bp_sm + "px)")) {

					if ( _this.container.hasClass('mobile-layout') ) { return; }

					// ==== Change layout: from Desktop to Mobile

					// update cellAlign to center
		  		_this.el.flickity('updateCellAlign',.5);
		  		
					_this.numberPerRow = _this.numberPerRowMobile;

					if (_this.container.hasClass('mobile-slider')) {
						_this.container.find('.slider').unwrap();
						_this.items.css('width','');
						_this.draw();
					} else {
						_this.items.css('width','');
						_this.el.flickity('resize');
					}
					_this.container.removeClass('desktop-layout').addClass('mobile-layout');
				} 
				else {

					// looping slider on desktop
					if ( !_this.container.hasClass('mobile-slider') ) {

						// ==== Update cellAlign
						var leftOffset = ( _this.container.innerWidth() -  _this.container.find('.container').width() ) / 2;
			  		var align = leftOffset / _this.container.innerWidth();
			  		_this.el.flickity('updateCellAlign',align);

						// ==== Change layout: Mobile to Desktop
						// Update item size
						_this.setItemSize();
						_this.draw();
					}

					if (_this.container.hasClass('desktop-layout')) { return; }
					
					_this.numberPerRow = _this.numberPerRowDesktop;

					// 3 card or less
					if ( _this.container.hasClass('mobile-slider') ) { 
						_this.destroy();
						_this.container.find('.slider').wrap('<div class="container"></div>');
						_this.container.removeClass('loading');
					} 
					_this.container.removeClass('mobile-layout').addClass('desktop-layout');
				}
			})

			return _this;
		}
	}

	return infiniteSlider.init(container);
	
}
