import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqbXNoanVsYjF2MHkzd3FrZ3IwY2RleWYifQ.5E7xp4Ka2htp87WOgKz8wg';

var mapboxStyle = 'mapbox://styles/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia';

var bp_sm = '800';

$(document).ready(function() {
  console.log("doc ready, init...");
  projectLotsMap.init();
})

var projectLotsMap = {
  mapExtent: [153.02058213, -27.25909836, 153.02362431, -27.25519248],
  mapContainerId: 'projects-map-container',
  mapId: 'projectLots-map',
  mapClusterMaxZoom: 14,
  defaultZoom: 13,
  minZoom: 0,
  maxZoom: 19,
  mapPadding: 100,
  pinSvg: '<svg class="icon" viewBox="0 0 20 20" x="0" y="0"><path d="M10 19a.78.78 0 0 0 .56-.23C10.82 18.5 17 12.18 17 8a7 7 0 0 0-7-7 7 7 0 0 0-7 7c0 4.18 6.18 10.5 6.44 10.77A.78.78 0 0 0 10 19zM7.65 8A2.35 2.35 0 1 1 10 10.39 2.35 2.35 0 0 1 7.65 8z" fill="currentColor"/></svg>',
  mapMarkers: [],
  mapInit: false,
  hasMoved: false,
  pinClicked: false,
  clusterRadius: 60,
  lotPloygons: [],
  lotToHighlightCoords: null,
  init: function() {
    var _this = this;
    // This will let you use the .remove() function later on for popup
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function() {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
      };
    }
    if ( window.allProjectLots == undefined ) {
      _this.data = null; 
      return;
    } else {
      _this.data = window.allProjectLots;
      console.log("==== _this.data ====");
      console.log(_this.data);
    }

    _this.drawMap();
  },
  drawMap: function() {
    var _this = this;
    _this.initMap();
    _this.addMapTilesLayer();
    _this.fetchMapData();
   _this.bindActions();
  },
  // Init map
  initMap: function() {
    var _this = this;


    //L.mapbox.accessToken = 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNtMXJnN2owbDBheWkyaW92OWFteXUzdDAifQ.-1tUb2IT6WNeOVIMLOs7xw';

    if ( _this.mapInit == true ) { return; }
    //if ( _this.projects.length < 1 ) { return; }

    var bounds = new L.LatLngBounds(
            new L.LatLng(_this.mapExtent[1], _this.mapExtent[0]),
            new L.LatLng(_this.mapExtent[3], _this.mapExtent[2])); 

    _this.map = L.map(_this.mapId, {
              worldCopyJump: !0,
              attributionControl: 1,
              center: [0, 0],
              zoom: 2,
              position: 'topright',
              scrollWheelZoom: 1,
              zoomControl: 0,
              fullscreenControl: true,
              style: mapboxStyle,
              // OR
              //fullscreenControl: {
              //    pseudoFullscreen: false // if true, fullscreen to page width and height
              //}
          }).fitBounds(bounds);

    //https://api.mapbox.com/styles/v1/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia.html?fresh=true&title=true&access_token=pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqbXNpYmtqbDF2YW4zd3Frb2IxcGpodDIifQ.qhUNdVRiHHqSd9mqqwo2hw#9.5/42.300857/-71.018942/0
    //https://api.mapbox.com/styles/v1/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia/wmts?access_token=pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqbXNpYmtqbDF2YW4zd3Frb2IxcGpodDIifQ.qhUNdVRiHHqSd9mqqwo2hw
   

    //https://api.mapbox.com/styles/v1/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqbXNpYmtqbDF2YW4zd3Frb2IxcGpodDIifQ.qhUNdVRiHHqSd9mqqwo2hw
    //This works: https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}

    var prosperGreyScaleStyle = 'https://api.mapbox.com/styles/v1/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}';
    var ausbuildStyle = 'https://api.mapbox.com/styles/v1/liquidmelbourne/ck0deobu40mui1cl5v5qg85h0/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}';

    L.tileLayer(prosperGreyScaleStyle, {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 19,
      id: 'mapbox.streets',
      accessToken: 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqbXNoanVsYjF2MHkzd3FrZ3IwY2RleWYifQ.5E7xp4Ka2htp87WOgKz8wg'
    }).addTo(_this.map);



    L.control.zoom({
         position:'topright'
    }).addTo(_this.map);



    //_this.map.addControl(new L.Control.Fullscreen());


    /*L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: !1,
        minZoom: _this.minZoom,
        maxZoom: _this.maxZoom,
        maxNativeZoom: _this.maxZoom,
    }).addTo(_this.map);*/

    _this.mapInit = true;
  },
  addMapTilesLayer: function(){
    var _this = this;
    var tileLayer = _this.data.mapTilesUrl + '{z}/{x}/{y}.png';
    L.tileLayer(tileLayer, {
        attribution: !1,
        minZoom: _this.mapMinZoom,
        maxZoom: _this.mapMaxZoom,
        maxNativeZoom: _this.mapMaxZoom,
        opacity: 1,
        attribution: 'Rendered with <a href="http://www.maptiler.com/">MapTiler</a>',
        tms: false
    }).addTo(_this.map);
  },
  fetchMapData: function() {
    var _this = this;

    

    var addedOptions = {
      clickable: !1,
      color: "8190A6"
    }
    //

    


    /******** Estate Marker ********/
    var estateIcon;
    var estateMarker;
    var estateGroup = [];

    var estateCoords = JSON.parse('{"lat":' + _this.data.latitude +', "lng":' + _this.data.longitude + '}');
    var estateName = _this.data.name;

    //var estateCoords = JSON.parse('{"lat":-37.57863574979214,"lng":145.0337986845}');
    //var estateName = "Sphynx";
    _this.estateFeatureGroup = new L.featureGroup(stageGroup);

    estateIcon = L.divIcon({
      className: 'estateDiv',
      html: "<h1>" + estateName + "</h1><h3>View Masterplan</h3>",
      iconSize: [100,40] 
    }); // Explicitly set to null or you will default to 12x12

    estateMarker = new L.marker(estateCoords, {
          //icon: statusIcon
          icon: estateIcon
    });

    estateMarker.addTo(_this.estateFeatureGroup);
    _this.map.addLayer(_this.estateFeatureGroup);

    _this.estateFeatureGroup.on('mouseover', function() { 
        console.log('_this.estateFeatureGroup mouseover !'); 
        //_this.setHighlight(this);
    }).on('mouseout', function() { 
        console.log('_this.estateFeatureGroup mouseout !'); 
        //_this.unsetHighlight(this);
    }).on('click', function() { 
     // zoom in on stage
      _this.map.setView(estateCoords, 16);   
    });

    /******** End: Estate Marker ********/


    /******** Stage Marker ********/
    

    var stageGroup = [];
    _this.stageFeatureGroup = new L.featureGroup(stageGroup);
    var stageIcon;
    var stageMarker;

    $.each(_this.data.estate_children, function(index, item) {

       if(item.map_layer == null || item.map_layer == '') return;
       if (item.map_layer) {

            /*
          var statusSVG = '/assets/svg/green.svg';
          var statusColour = '#228B22';

          var childAddedOptions = {
            clickable: 1,
            color: statusColour, //E34747
            id: item.id,
            opacity: 0,
            fillOpacity: 0,
            weight: 4,
            smoothFactor:1,
          }

          childFeatureGroup.options = {
             id: item.id,
          };

          var poly = L.polygon(item.map_layer.latlngs, $.extend(item.map_layer.options, childAddedOptions));
          poly.addTo(childFeatureGroup);
        */
        

          if (item.marker_coordinates) {

            stageIcon = L.divIcon({
              className: 'stageDiv',
              html: "<h1>" + item.name + "</h1>",
              iconSize: [100,30] 
              //iconAnchor:   [40, 40], // point of the icon which will correspond to marker's location
            });
            //marker = new L.marker(poly.getBounds().getCenter(), {
            stageMarker = new L.marker(item.marker_coordinates, {
                //icon: statusIcon
                icon: stageIcon
            });

            //marker.getElement().style.width = '50px';



            var stageCoords = JSON.parse('{"lat":' + item.marker_coordinates.lat +', "lng":' + item.marker_coordinates.lng + '}');

            stageMarker.addTo(_this.stageFeatureGroup);

            stageMarker.on('mouseover', function() { 
                console.log('stageMarkermouseover !'); 
                //_this.setHighlight(this);
            }).on('mouseout', function() { 
                console.log('stageMarker mouseout !'); 
                //_this.unsetHighlight(this);
            }).on('click', function() { 
             // zoom in on stage
              console.log("stageMark click");
              _this.map.setView(stageCoords, 18);   
            });


          }
        }
        _this.map.addLayer(_this.stageFeatureGroup);
        //s.push(childFeatureGroup);


    });
    

    /*
    var stagesJSON = '[{"name": "Stage A","release": "Release 1", "lat":-37.576029,"lng":145.033666},{"name": "Stage B","release": "Release 1", "lat":-37.576032,"lng":145.033632}]';

    var stageIcon;
    var stageMarker;
    var stageGroup = [];
    var stageCoords = JSON.parse('{"lat":-37.57864,"lng":145.0334}');
    var stageName = "Stage A";
    var stageRelease = "Release 1";
    _this.stageFeatureGroup = new L.featureGroup(stageGroup);

    stageIcon = L.divIcon({
      className: 'stageDiv',
      html: "<h1>" + stageName + "</h1><h3>" + stageRelease + "</h3>",
      iconSize: [100,40] 
    }); // Explicitly set to null or you will default to 12x12

    stageMarker = new L.marker(stageCoords, {
          //icon: statusIcon
          icon: stageIcon
    });

    stageMarker.addTo(_this.stageFeatureGroup);

    _this.map.addLayer(_this.stageFeatureGroup);

    _this.stageFeatureGroup.on('mouseover', function() { 
        console.log('_this.stageFeatureGroup mouseover !'); 
        //_this.setHighlight(this);
    }).on('mouseout', function() { 
        console.log('_this.stageFeatureGroup mouseout !'); 
        //_this.unsetHighlight(this);
    }).on('click', function() { 
     // zoom in on stage
      _this.map.setView(stageCoords, 18);   
    });
    */
    


    /******** End: Stage Marker ********/

    $.each(_this.data.estate_lots, function(index, item) {

      if(item.map_layer == null || item.map_layer == '') return;


    
       var divIcon, statusIcon, marker,stageMarker, poly;
       // this references the map_layer in the json for each lot, creates the filledin non-clickable polygo
        console.log("==== fetchMapData : value.map_layer.latlngs ==== ");
        //console.log(item);
        //console.log(value);
        //console.log(value.map_layer);
        console.log(item.map_layer.latlngs[0]);

        console.log("==== item.status ====");
        console.log(item.status);
        var statusSVG = '/assets/svg/green.svg';
        var statusColour = '#228B22';
        if(item.status == 'sold') {
          statusSVG = '/assets/svg/red.svg';
          statusColour = '#E34747';
        } else if(item.status == 'deposit') {
          statusSVG = '/assets/svg/orange.svg';
          statusColour = 'orange';
        }


        if (item.map_layer) {

          var hlpContent = '';

          var addedOptions = {
            clickable: 1,
            color: statusColour, //E34747
            id: item.id,
            opacity: 0,
            fillOpacity: 0,
            weight: 4,
            smoothFactor:1,
          }

          var lotGroup = [];
          var featureGroup = new L.featureGroup(lotGroup);
          featureGroup.options = {
             id: item.id,
             status: item.status
          };

          var poly = L.polygon(item.map_layer.latlngs, $.extend(item.map_layer.options, addedOptions));
          //_this.lotGroup.push(poly);
          poly.addTo(featureGroup);

        

          if (item.marker_coordinates) {

            //console.log("=== item.marker_coordinates ====");
            //console.log(item.marker_coordinates);
            //console.log(item.marker_coordinates.lat);

            divIcon = L.divIcon({
              className: 'iconsDiv ' + item.status, 
              iconSize: [16,16] 
            }); // Explicitly set to null or you will default to 12x12

            /*
            statusIcon = L.icon({
              //iconUrl: '/assets/img/map/icn-pin@2x.png',
              iconUrl: statusSVG,
              iconSize: [20,20],
              //iconAnchor:   [40, 40], // point of the icon which will correspond to marker's location
            });
            */
            //marker = new L.marker(poly.getBounds().getCenter(), {
            var marker = new L.marker(item.marker_coordinates, {
                //icon: statusIcon
                icon: divIcon,
                iconSize: [16,16],
                iconAnchor: [8, 8]
            });

            //marker.getElement().style.width = '50px';

            marker.addTo(featureGroup);
          } //end marker

          if (item.hlps.length > 0) {
              hlpContent = '<p>Packages:<br />';
              $.each(item.hlps, function(index, hlp) {
                var hlpURL = "/demo/sf-projects/elements/house-and-land-package/"  + hlp.id;
                hlpContent += '<a target="_blank" href="' + hlpURL +'">' + hlp.name +'</a><br />'
              });
              hlpContent += '</p>';
          }

          //s.push(n);
          //lotGroup.push(marker);
          var popupOptions = {
            clickable: 1,
            className:'myPopup'
          };

          var lotURL = "/demo/sf-projects/elements/property/"  + item.id;


          var popupContent = '<p><b>Lot ' + item.number + '<b/> | <a href="' + lotURL + '">View Lot</a></p>';
          popupContent += hlpContent;
          var popup = L.popup(popupOptions).setContent(popupContent);
          
          featureGroup.bindPopup(popup);

          featureGroup.on('mouseover', function() { 
              console.log('featureGroup mouseover !'); 
              _this.setHighlight(this);
          }).on('mouseout', function() { 
              console.log('featureGroup mouseout !'); 
              _this.unsetHighlight(this);
          }).on('click', function() {
            this.openPopup(); 
            //alert('Clicked on a featureGroup! Id: ' + this.options.id);
            //console.log(this.options); 
            //window.location.href = "/demo/sf-projects/elements/property/"  + this.options.id;
          });
          
          _this.lotPloygons.push(featureGroup);
        } // end if item.map.layer

        if(_this.data.lotToHighlight != null && _this.data.lotToHighlight == item.id ) {

          if(item.marker_coordinates) {
            console.log("===== lotToHighlight =====");
            console.log(_this.data.lotToHighlight);
            _this.highlightLot(_this.data.lotToHighlight);
            _this.lotToHighlightCoords = item.marker_coordinates;
          }
          
        }
        

    }); //end each

    _this.nonEditableLayers = new L.FeatureGroup(_this.lotPloygons);

    _this.map.addLayer(_this.nonEditableLayers);

    
    if(_this.data.lotToHighlight != null) _this.zoomToLot(_this.data.lotToHighlight);
    else _this.map.setView([_this.data.latitude, _this.data.longitude], 14);  

    //_this.map.removeLayer( _this.nonEditableLayers );
    //_this.map.removeLayer( _this.stageFeatureGroup);
    //_this.map.removeLayer( _this.estateFeatureGroup);

   

  },
  highlightLot: function(lot) {

    var _this = this;
    //_this .bindEditPolygon()
    console.log('hightlight:' + lot);

    for(var i = 0; i < _this.lotPloygons.length; i++){
      console.log("=== for i: " + _this.lotPloygons[i].options.id + " ===");
      console.log("=== for i: " + lot + " ===");
      //console.log(_this.lotPloygons[i].options.id);]

      if(_this.lotPloygons[i].options.id ==  lot){
            console.log("===  found it! === ");  
            // set colour
            _this.lotPloygons[i].setStyle({
                'color': '#000',
                'opacity': 1.0,
                'weight': parseInt(10)
            });     
        }
    }


  },
  zoomToLot: function(lot) {

    var _this = this;
    //_this .bindEditPolygon()
    console.log('zoomToLot:' + lot);
    console.log(_this.lotToHighlightCoords);
    
    if(_this.lotToHighlightCoords != null) {
      if(_this.data.zoomToLevel != null) _this.map.setView([_this.lotToHighlightCoords.lat, _this.lotToHighlightCoords.lng], _this.data.zoomToLevel);
      else _this.map.setView([_this.lotToHighlightCoords.lat, _this.lotToHighlightCoords.lng], _this.defaultZoom);    
    }  

  },
  bindActions: function() {
    var _this = this;
    //_this .bindEditPolygon()
    _this.bindMapZoom();

  },
  bindMapZoom: function() {
    var _this = this;
    
    _this.map.on('zoomstart', function() {
      var zoomLevel = _this.map.getZoom();
      console.log("zoomstart:zoomLevel: " + zoomLevel);
      if (_this.map.hasLayer( _this.nonEditableLayers)) {
          _this.map.removeLayer( _this.nonEditableLayers);
      }
      if (_this.map.hasLayer( _this.stageFeatureGroup)) {
          _this.map.removeLayer( _this.stageFeatureGroup);
      }
    });

    _this.map.on('zoomend', function() {
      var zoomLevel = _this.map.getZoom();
      //alert(_this.map.getZoom());
      console.log("zoomend: zoomLevel: " + zoomLevel);
      
      //Less than or equal 14, show Estate, else greater than 14, hide it
      if(zoomLevel <= 14) {
        if (_this.map.hasLayer( _this.estateFeatureGroup)){
            console.log(" _this.estateFeatureGroup layer already added");
        } else {
            _this.map.addLayer( _this.estateFeatureGroup);
        }

      } else if(zoomLevel > 14) {
        if (_this.map.hasLayer( _this.estateFeatureGroup)) {
            _this.map.removeLayer( _this.estateFeatureGroup);
        } 
      }

      //15-18, show 
      if(zoomLevel >= 15 && zoomLevel <= 17 ) {

        if (_this.map.hasLayer( _this.stageFeatureGroup)){
            console.log(" _this.stageFeatureGroup layer already added");
        } else {
            _this.map.addLayer( _this.stageFeatureGroup);
        }
      } else {

        if (_this.map.hasLayer( _this.stageFeatureGroup)) {
            _this.map.removeLayer( _this.stageFeatureGroup);
        }

      }

      //18-20, show nonEditableLayers
      if(zoomLevel >= 18 ) {
        if (_this.map.hasLayer( _this.nonEditableLayers)){
            console.log(" _this.nonEditableLayers layer already added");
        } else {
            _this.map.addLayer( _this.nonEditableLayers);
        }
      } else {

        if (_this.map.hasLayer( _this.nonEditableLayers)) {
            _this.map.removeLayer( _this.nonEditableLayers);
        }

      }




      /*
      if(zoomLevel < 16) {

        if (_this.map.hasLayer(_this.stageFeatureGroup)) {
            _this.map.removeLayer( _this.stageFeatureGroup);
        } else {
            console.log("no _this.stageFeatureGroup layer active");
        }

      }
      else if(zoomLevel < 18) {


        if (_this.map.hasLayer( _this.stageFeatureGroup)){
            console.log(" _this.stageFeatureGroup layer already added");
        } else {
            _this.map.addLayer( _this.stageFeatureGroup);
        }

        if (_this.map.hasLayer( _this.nonEditableLayers)) {
            _this.map.removeLayer( _this.nonEditableLayers);
        } else {
            console.log("no  _this.nonEditableLayers layer active");
        }
      } else if (zoomLevel => 18) {

        if (_this.map.hasLayer(_this.stageFeatureGroup)) {
            _this.map.removeLayer( _this.stageFeatureGroup);
        } else {
            console.log("no _this.stageFeatureGroup layer active");
        }

        

        if (_this.map.hasLayer( _this.nonEditableLayers)){
            console.log(" _this.nonEditableLayers layer already added");
        } else {
            _this.map.addLayer( _this.nonEditableLayers);
        }
        

        var newzoom = '' + (zoomLevel - 2) +'px';
        $('#projectLots-map .iconsDiv').css({'width':newzoom,'height':newzoom,'background-size':newzoom + ' ' + newzoom}); 

      }*/
    });
  },
  setHighlight: function(layer) {
    // Check if something's highlighted, if so unset highlight
    console.log("==== in setHighlight ====")
    console.log(layer);
    var style = {
      'default': {
        //'color': '#228B22'
      },
      'highlight': {
        //'color': '#228B22',
        'opacity': '1'
      }
    };
    layer.setStyle(style.highlight);
  },
  unsetHighlight: function(layer) {
    // Check if something's highlighted, if so unset highlight
    console.log("==== in unsetHighlight ====")
    console.log(layer);
    var style = {
      'default': {
        //'color': '#228B22'
      },
      'highlight': {
        //'color': '#228B22',
        'opacity': '0'
      }
    };
    layer.setStyle(style.highlight);
  },
  resetFitBounds: function(animate) {
    var _this = this;

    if ( _this.mapInit == false ) { return; }
    // var easing = !animate ? 'linear: true' : '';
    if(_this.mapMarkers.length < 2) {
      var options = {
        padding: {top: _this.mapPadding, bottom:_this.mapPadding, left: _this.mapPadding, right: _this.mapPadding},
        maxZoom: 16
      }
    } else {
      var options = {
        padding: {top: _this.mapPadding, bottom:_this.mapPadding, left: _this.mapPadding, right: _this.mapPadding},
        // maxZoom: _this.mapZoom
      }
    }
    

    if ( !animate ) {
      options.linear = true;
    }

    if ( !status ) {
      status = null;
    }

    _this.map.fitBounds(_this.mapBbox, options);

  },

  // Create layers with all features for this query (all projects included)
  loadPins: function() {
    var _this = this;

    _this.map.addSource('projects', {
      type: 'geojson',
      data: _this.data,
    });

    _this.map.loadImage(_this.mapPin, function(error, image) {
      if (error) throw error;
      _this.map.addImage('pin', image);
   
      _this.map.addLayer({
        "id": "pins",
        "type": "symbol",
        "source": "projects",
        "layout": {
          "icon-image": "pin",
          "icon-size": .5,
          "icon-allow-overlap": true
        }
      });

      _this.updateMapPosition(_this.projects);

    });

    _this.map.loadImage(_this.mapPinActive, function(error, image){
      if (error) throw error;
      _this.map.addImage('pin-active', image);

      _this.map.addLayer({
        "id": "pins-hover",
        "type": "symbol",
        "source": "projects",
        "layout": {
          "icon-image": "pin-active", //gold
          "icon-size": .5,
          "icon-allow-overlap": true,
        },
        "filter": ["==", "id", ""]
      });

    });

    // === Pin hover state
    _this.map.on('mousemove', 'pins', function (e) {
      _this.map.getCanvas().style.cursor = 'pointer';
      // issue: if pin hovered pin is behind another pin, the gold "pin-hover" goes over the blue pins and the icons flicker..
      // _this.map.setFilter("pins-hover", ["==", "id", e.features[0].properties.id]);
    });
    _this.map.on('mouseleave', 'pins', function () {
      _this.map.setFilter("pins-hover", ["==", "id", ""]);
      _this.map.getCanvas().style.cursor = '';
    });


    // === Click on pin: show popup
    _this.map.on('click', 'pins', function(e) {
      var feature = e.features[0];
      var prop = feature.properties;
        
      // Add active style to this pin
      // _this.setActiveSymbol(feature);

      // Fly to the point and center pin + popup
      _this.map.flyTo({
        center: feature.geometry.coordinates,
        zoom: _this.map.getZoom() < 1 ? 1 : _this.map.getZoom(), // current zoom or zoom in if max zoom out (no room for popup)
        offset: [0,-80] // fit popup in view
      });

      // Close all other popups and display popup for clicked store
      _this.createPopUp(feature);

    })
  },

  // Fly to correct position
  updateMapPosition: function(features) {
    var _this = this;

    if ( features ) {
      _this.mapBbox = new mapboxgl.LngLatBounds();
      features.forEach(function(f) {
        _this.mapBbox.extend(f.geometry.coordinates);
      });
    }

    _this.resetFitBounds(false);
    _this.map.once('moveend', fitBoundsEnd);

    function fitBoundsEnd() {
      $('#'+_this.mapContainerId).removeClass('loading');
      _this.map.off('moveend', fitBoundsEnd);
    }
    _this.map.on('resize',function(){
      // recenter map (with animation)
      _this.resetFitBounds(true);

    })
  },

  // Active pin style
  setActiveSymbol: function(feature) {

    var _this = this;
    // get current featured (looking for id)
    var p = _this.projects.filter(function(f) {
      return f.properties.id === feature.properties.id;
    }, feature);

    var geojson = {
      "type": "FeatureCollection",
      "features": p
    };

    if ( _this.map.getSource('active_pin') ) {
      _this.map.removeSource('active_pin');
    }  
    if ( _this.map.getLayer('pin-active') ) {
      _this.map.removeLayer('pin-active');
    }

    _this.map.addSource('active_pin', {
      type: 'geojson',
      data: geojson,
    });

    _this.map.addLayer({
      "id": "pin-active",
      "type": "symbol",
      "source": "active_pin",
      "layout": {
        "icon-image": "pin-active", // already loading for pins-hover
        "icon-size": .5,
        "icon-allow-overlap": true,
      }
    });

  },

  // Remove active pin style
  removeActiveSymbol: function() {
    var _this = this;
    if ( _this.map.getSource('active_pin') ) {
      _this.map.removeSource('active_pin');
    }
    if ( _this.map.getLayer('pin-active') ) {
      _this.map.removeLayer('pin-active');
    }
  },

  removePopup: function() {
    if ( this.mapInit == false ) { return; }
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    // Check if there is already a popup on the map and if so, remove it
    if (popUps[0]) popUps[0].remove();
  },

  // Add popup to the DOM
  createPopUp: function(currentFeature) {
    var _this = this;
    if ( _this.mapInit == false ) { return; }
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    // Check if there is already a popup on the map and if so, remove it
    if (popUps[0]) popUps[0].remove();

    var lngLat = currentFeature.geometry.coordinates;

    var prop = currentFeature.properties;

    var location = prop.location.length < 1 ? '' : '<h4 class="location">'+ prop.location +'</h4>';

    var markup = [
    '<div class="header">'+ location +'</div>',
      '<a href="'+ prop.link +'" class="map-popup-project">',
        '<span class="img bg-img-cover" style="background-image:url(\''+prop.imgUrl+'\')"></span>',
        '<span class="body">',
          '<h3 class="heading">'+prop.title+'</h3>',
          '<div><span class="text-link grey">View Client</span></div>',
        '</span>',
      '</a>',
    ].join('');

    var popup = new mapboxgl.Popup({
      closeOnClick: true,
      anchor: 'top',
    })
    .setLngLat(lngLat)
    .setHTML(markup)
    .addTo(_this.map);

    // if ($('html').hasClass('touch')) {
    //     _this.map.dragPan.enable();
    //   }

    // update close icon
    // var path = 'http://' + window.location.hostname;
    // $('.mapboxgl-popup-close-button').html('<img alt="close" src="'+ path +'/images/mapbox/map-close.png" />');

    // popup.on('close',function(){
      // _this.removeActiveSymbol();
      // _this.pinClicked = false;
      // if ($('html').hasClass('touch')) {
      //     _this.map.dragPan.disable();
      //   }
    // })

  },
  // Destroy map
  destroyMap: function() {
    if ( this.mapInit == false ) { return; }
    this.map.remove();
    this.mapInit = false;
  },
  destroy: function() {
    this.data = null;
    this.projects = null;

    this.destroyMap();
  }
}