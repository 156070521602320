import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNtMXJnN2owbDBheWkyaW92OWFteXUzdDAifQ.-1tUb2IT6WNeOVIMLOs7xw';

var mapboxStyle = 'mapbox://styles/liquidmelbourne/ck0byyf882iuv1cpiad2pzlia';

var bp_sm = '800';



// This will let you use the .remove() function later on
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}


var markers = {
  "type": "FeatureCollection",
  "features": [{
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [144.943988, -37.836215]
    },
    "properties": {
      "id": 0,
      "city": "Melbourne (HQ)",
      "title": "Melbourne (HQ)",
      "description": ""
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [151.215256,-33.856159]
    },
    "properties": {
      "id": 1,
      "city": "Sydney",
      "title": "Sydney",
      "description": "Office coming soon!<br>We visit Sydney once a month currently."
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [153.030337, -27.470288]
    },
    "properties": {
      "id": 2,
      "city": "Brisbane",
      "title": "Brisbane",
      "description": ""
    }
  }],
};


var mapCenter = [133.5367715, -34.9485491448568];
var mapZoom = 4;
var mapMaxZoom = 10;

var boundPaddingLarge = {top: 150, bottom:150, left: 200, right: 200};
// var boundPaddingMedium = {top: 50, bottom:50, left: 100, right: 100};
var boundPaddingSmall = {top: 50, bottom:50, left: 50, right: 50};

var boundPadding = boundPaddingLarge;

var mapMarkers = [];

var containerId = 'contact-map-container';
var mapId = 'contact-map';


$(document).ready(function() {
  if (Modernizr.mq('(max-width: '+bp_sm+'px)')) { boundPadding = boundPaddingSmall; }

  if ( $('#' + mapId).length > 0 ) {
    mapInit();
  }
})



function mapInit() {

  var bounds = new mapboxgl.LngLatBounds();
  markers.features.forEach(function(feature) {
      bounds.extend(feature.geometry.coordinates);
  });

  function mapFitBounds() {
    map.fitBounds(bounds, {
      padding: boundPadding
    });
  }

  var maxBounds = [
    [103.491211, -43.644026], // lng, lat (south west)
    [162.905273, -11.264612] // lng, lat (north west)
  ];

  var map = new mapboxgl.Map({
    container: mapId,
    style: mapboxStyle,
    // style: "mapbox://styles/mapbox/light-v9",
    // center: [144.68, -36.00], // starting position [lng, lat]
    // center: mapCenter,
    zoom: mapZoom, // between 0 and 22.
    maxZoom: mapMaxZoom,
    attributionControl: false, // disable bottom right credits and links
    scrollZoom: true
    // trackResize: true,
    // maxBounds: maxBounds
  });

  mapFitBounds();

  // disable map rotation using right click + drag
  map.dragRotate.disable();
  // disable map rotation using touch rotation gesture
  map.touchZoomRotate.disableRotation();

  // Add zoom and rotation controls to the map.
  // map.addControl(new mapboxgl.NavigationControl());


  // == Custom zoom
  var zoomControls = $('#'+containerId).find('.map-zoom-controls .control');
  zoomControls.on('click', function(e) {
    e.preventDefault();

    var options = {
      offset: [0, 0]
    }
    // zoom in
    if ( $(this).hasClass('zoom-in') ) {
      map.zoomIn(options);
    } 
    // zoom out
    else if ( $(this).hasClass('zoom-out') ) {
      map.zoomOut(options);
    }
  })



  map.on('resize', function(e) {

    if (Modernizr.mq('(max-width: '+bp_sm+'px)')) { 
      boundPadding = boundPaddingSmall;
    }
    // else if (Modernizr.mq('(max-width: 1280px)')) { 
    //   boundPadding = boundPaddingMedium;
    // } 
    else {
      boundPadding = boundPaddingLarge
    }

    mapFitBounds();
  })


  // load layer with markers
  map.on('load',function(e) {
    
    map.addSource('markers', {
      type: 'geojson',
      data: markers
    }); 
      
  })

    
  markers.features.forEach(function(marker) {
    // Create a div element for the marker
    var el = document.createElement('div');
    // Add a class called 'marker' to each div
    el.className = 'marker';
    // By default the image for your custom marker will be anchored
    // by its top left corner. Adjust the position accordingly
    // Create the custom markers, set their position, and add to map
    var $element = $(el).html('<div class="dot"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 265"><circle fill="" cx="132.5" cy="132.5" r="126.3"/><g fill="currentColor"><path d="M120.3 77.7h24v109.7h-24zM155 170.5c4.2-9.9 18.1-9.9 22.3 0 1.2 2.9 1.2 6.3 0 9.2-4.2 9.9-18.1 9.9-22.3 0-1.3-3-1.3-6.3 0-9.2"/></g></svg></div><h5>'+marker.properties.city+'</h5>');
    var options = {
      offset: [0, 0],
    }
    var m = new mapboxgl.Marker($element[0], options)
      .setLngLat(marker.geometry.coordinates)
      .addTo(map);

    mapMarkers[marker.properties.id] = m;


    el.addEventListener('click', function(e) {
      var activeItem = document.getElementsByClassName('active');
      // 1. Fly to the point
      flyTo(marker);
      // 2. Close all other popups and display popup for clicked store
      createPopUp(marker);
    });


  });


  function flyTo(currentFeature) {
    // map.flyTo({
    //   center: currentFeature.geometry.coordinates,
    //   zoom: 6
    // });
    var offset = Modernizr.mq('(max-width: '+bp_sm+'px)') ? [0, 60] : [0,0];
    map.easeTo({
      center: currentFeature.geometry.coordinates,
      offset: offset
      // zoom: 4
      // zoom: mapZoom
    });
  }

  function createPopUp(currentFeature) {

    // Check if there is already a popup on the map and if so, remove it
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    if (popUps[0]) popUps[0].remove();
    $('#'+mapId).find('.marker').removeClass('active');

    var $marker = $(mapMarkers[currentFeature.properties.id]._element);
    $marker.addClass('active');


    var title = '<h5 class="title">' + currentFeature.properties.title + '</h5>';
    var details = '<p class="content">' + currentFeature.properties.description + '</p>';
    var link = currentFeature.properties.link ? '<a href="'+ currentFeature.properties.link +'" target="_blank" class="text-link white">Get directions on Google Maps</a>' : '';

    var options = {
      closeOnClick: false, 
      offset:0,
      anchor: 'bottom'
    };

    var popup = new mapboxgl.Popup(options)
      .setLngLat(currentFeature.geometry.coordinates)
      .setHTML(title + details + link)
      .addTo(map);

    popup.on('close', function(e) {
      $marker.removeClass('active');
    })
  }

}

