<!-- https://codepen.io/iPrime/pen/bRJrRV -->

<template>
  <div id="textanim">
  <button v-on:click="show = !show">
    Toggle
  </button>
  <transition name="fade">
    <p v-if="show">hello</p>
  </transition>
  <!--
  <transition name="fadeOne">
    <div v-show="show" class="animContainer">
      <p class="One">ANIMATION</p>
    </div>
    </transition>
    <transition name="fadeTwo">
    <div v-show="show" class="animContainer">
      <p class="Two">using Vue.js transition and clipping path</p>
    </div>
    </transition>
  -->
</div>
</template>

<script>
  
    export default {
       
        data: function() {
            return {
                show: true
            }
        }
    };
</script>

<style>


#textanim .animContainer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

#textanim  button {
  color: #435466;
  font-size: 15px;
  font-weight: 600;
  background-color: #4DBA87;
  margin: auto;
  width: 100px;
  height: 50px;
  border: #3B3561;
}

#textanim  p {
  text-align: center;
  font-size: 50px;
  font-smoothing: antialiased;
  overflow: hidden;
  color: #4DBA87;
  margin: 1px;
}

 .Two {
  font-size: 18px;
  font-family: Georgia, serif;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fadeOne-enter-active, .fadeOne-leave-active {
  transition: all .5s ease-out;
}
.fadeOne-enter, .fadeOne-leave-to {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transform: translate(0%, 100%);
}

.fadeTwo-enter-active, .fadeTwo-leave-active {
  transition: all .5s ease-out;
}
.fadeTwo-enter, .fadeTwo-leave-to {
  clip-path: polygon(0% 100%, 100% 100%, 0% 100%, 0% 100%);
  transform: translate(0%, -100%);
}

</style>