// Using Flickity

var bp_md = '1220';

$(document).ready(function() {
  initImgMosaics();
});

// ======== Image Mosaic: slider on mobile (about page)

var imgMosaic_instances = [];

var initImgMosaics = function() {

  var imgMosaic = $('.img-mosaic');

  if ( imgMosaic.length ) {
    imgMosaic.each(function(){
      imgMosaic_instances.push(initImgMosaic($(this)));
    })

  }
}
var destroyImgMosaics = function() {
  var instance;
  while (imgMosaic_instances.length) {
    instance = imgMosaic_instances.shift();
    instance.destroy();
  }
}

var initImgMosaic = function(container) {

  var imgMosaic = {
    initialized: false,
    container: null,
    slider: null,

    draw: function(){
      var _this = this;

      _this.container.addClass('init');
      _this.container.removeClass('loading');

      // dont init again if already init
      if ( _this.initialized == true ) { return; } 

      _this.slider.flickity({
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true, // looping
        imagesLoaded: true,
        draggable: true,
        arrowShape: 'M96.7 47.8h-88l13.6-19.3c.8-1.2.6-2.8-.6-3.6-1.2-.8-2.8-.6-3.6.6L1.4 49c-.6.9-.6 2.1 0 3L18 75.5c.5.7 1.3 1.1 2.1 1.1.5 0 1-.2 1.5-.5 1.2-.8 1.5-2.5.6-3.6L8.5 53h88.2c1.4 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6z'
      });
      _this.el = _this.slider;

      _this.initialized = true;
      _this.updateCount(0);

      _this.el.on( 'select.flickity', function( event, index ) {
        _this.updateCount(index);
      });

    },
    updateCount: function(selectedId) {
      var _this = this;
      var num = ('0' + (selectedId+1)).slice(-2);
      _this.container.find('.slider-count .current').html(num);
    },

    destroy: function() {
      var _this = this;
      if ( !_this.el ) return;

      _this.el.flickity('destroy');

      _this.container.addClass('loading');
      _this.container.removeClass('init');
      _this.initialized = false;
    },

    init: function(container) {
      var _this = this;

      if (container === undefined || container.length < 1) return;

      // === Variables
      _this.container = container;
      _this.slider = _this.container.find('.slider');
      _this.items = _this.container.find('.slider-item');


      // Draw slider on mobile only
      if (Modernizr.mq("screen and (max-width:" + bp_md + "px)")) {
        _this.draw();
      }

      // On resize: draw slider on mobile if not already done
      $(window).on('resize',function() {
        if (Modernizr.mq("screen and (max-width:" + bp_md + "px)") && _this.initialized == false ) {
          _this.draw();
        }
      })

      return _this;
    }
  }

  return imgMosaic.init(container);
  
}
