<template>

	<vue-typed-js :strings="sentences" :typeSpeed="typeSpeed" :backSpeed="backSpeed" :startDelay="startDelay" :fadeOut="false" :loop="true" :showCursor="true" :cursorChar="'_'" :backDelay="backDelay">
	  <h1 class="typing"></h1>
	</vue-typed-js>

</template>

<script>


import { VueTypedJs } from "vue-typed-js";


export default {
  components: {
    VueTypedJs
  },
  props: ['sentences'],
  data() {
    return {
  		typeSpeed: 50,
  		backSpeed: 20,
  		startDelay: 1000,
  		backDelay: 2000,
    };
  },
  methods: {
  
  }
};
</script>