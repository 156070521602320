$(function() {
    var options = $('.options').find('input[type="checkbox"]');

    // add scale up to all layers
    options.each(function() {
        var name = $(this).attr('name');
        console.log(name);
        $('#' + name).addClass('scale-up');
        $('#' + name).addClass('animate-scale');
    })

    options.on('change', function() {

        // Handle dependency
        handleDependency(this);

        // Handle exclusive
        handleExclusive(this);

        // Handle incompatible
        handleIncompatible(this);

        // Show/Hide SVG Layer
        if(this.checked) {
            fadeInLayer(this);
        } else {
            fadeOutLayer(this);
        }
    });

    function fadeInLayer(input) {
        $('#' + input.name).fadeIn();
        $('#' + input.name).removeClass('scale-up');
        $('#popup-'+$(input).attr('id')).removeClass('d-none');
    }

    function fadeOutLayer(input) {
        $('#' + input.name).fadeOut();
        $('#' + input.name).addClass('scale-up');
        $('#popup-'+$(input).attr('id')).addClass('d-none');
    }

    // ===  Exclusive
    // If exclusive selected uncheck other inputs. When selected an input uncheck exclusive ones.
    function handleExclusive(input) {
        // If exclusive
        if ( $(input).parent('.form-group').attr('data-exclusive') == 'true' ) {
            options.not($(input)).prop( "checked", false );
            options.not($(input)).each(function() {
                fadeOutLayer(this);
                handleDependency(this);
                handleIncompatible(this);
            })
        }
        // Not exclusive 
        else {
            var $exclusiveInputs = $('.options .form-group[data-exclusive="true"] input[type="checkbox"]');
            $exclusiveInputs.prop( "checked", false );
            $exclusiveInputs.not($(input)).each(function() {
                fadeOutLayer(this);
            })
        }
    }

    // ===  Dependency
    // When selecting children check parent. When unselecting parent uncheck children
    function handleDependency(input) {
        // child changed: toggle parent
        if ( $(input).parent('.form-group').attr('data-pho') != 'false' ) {
            var parentId = $(input).parent('.form-group').attr('data-pho');
            var $parentInput = $('.options .form-group[data-sfid="'+parentId+'"] input[type="checkbox"]');
            if(input.checked) {
                $(input).removeAttr('disabled');
                $parentInput.prop( "checked", true );
                fadeInLayer($parentInput[0]);
            }
        } 
        // parent changed: fade in/out children
        else {
            var id = $(input).parent('.form-group').attr('data-sfid');
            var $children = $('.options .form-group[data-pho="'+id+'"]');
            if(input.checked) {
                $children.find('input').removeAttr('disabled');
            } else {
                $children.find('input').attr('disabled', true);

                $children.find('input[type="checkbox"]').prop( "checked", false );
                $children.each(function() {
                    var input = $(this).find('input[type="checkbox"]')[0];
                    fadeOutLayer(input);
                })
            }
        }
    }

    // ===  Incompatible
    // If input selected is incompatible with another input, disable the other one.
    function handleIncompatible(input) {
        if ( $(input).parent('.form-group').attr('data-incompatible') != '[]') {
            var ids = JSON.parse($(input).parent('.form-group').attr('data-incompatible'));
            var thisChecked = input.checked;
            for ( var i = 0; i < ids.length; i++) {
                var id = ids[i].sfid;
                var incompatibleInput = $('.options .form-group[data-sfid="'+id+'"] input[type="checkbox"]');
                if ( thisChecked ) {
                    incompatibleInput.prop( "checked", false );
                    incompatibleInput.attr('disabled', true);
                } else {
                    incompatibleInput.removeAttr('disabled');
                }
            }
        }
    }
});
