<template>

  <div id="blog-extra" class="container" v-cloak>
    <div class="news-listing">
        <div v-for="blog in blogs" class="news-listing-item">
            <article class="news-post">
                <a v-bind:href="blog.url" v-on:mouseover="prerenderLink" class="post-inner">
                    <div class="post-inner-tinfo">
                            <span class="date">{{ blog.postDate }} </span> 
                            <span class="time">{{ blog.readingTimeInMinutes }} min read</span>  
                    </div>

                    
                      <div class="post-img">
                          <picture>
                             <img v-bind:src="blog.blogImageUrl" v-bind:alt="blog.title" />
                          </picture>
                      </div>
                    <div class="post-body">
                        <div class="post-body-inner">
                        <p class="post-heading lead">{{ blog.title }}</p>
                        <p class="post-description">{{ blog.blogIntro }}</p>
                        <span class="text-link">Read On</span>
                    </div>
                </div>
                </a>
            </article>
        </div>
    </div>
    <div class="blog-load-more text-center">
      <button :class="btnClasses" v-show="nextPage" v-on:click="loadMoreBlogs()">
        <span class="text">Load More Articles</span>
        <span class="spinner-container"><svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="currentColor" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" transform="rotate(94.2792 25 25)"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg></span>
      </button>
    </div>
          
</div>


</template>

<script>

export default{

  data: function() {
    return {
      nextPage: 0,
      showSpinner: 1,
      btnClassesDefault: 'primary-btn btn-has-spinner',
      btnClassesLoading: 'primary-btn btn-has-spinner loading',
      btnClasses: this.btnClassesDefault,
      blogs: [],
    }
  },
  mounted() { 
   
    this.$http.get('/api/blog.json').then(function(data) {
        if (data.body.meta.pagination.total_pages > 1)
            this.nextPage = 2;
        this.btnClasses = this.btnClassesDefault;
    });
  },
  methods: {
  
   loadMoreBlogs: function() {
        this.btnClasses = this.btnClassesLoading;
        this.$http.get('/api/blog.json?pg=' + this.nextPage).then(function(data) {
            this.blogs = this.blogs.concat(data.body.data);
            this.nextPage = this.nextPage + 1;
            if (data.body.meta.pagination.total_pages == data.body.meta.pagination.current_page)
                this.nextPage = 0;
            this.btnClasses = this.btnClassesDefault;
        });
    },
    prerenderLink: function(e) {
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        let ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
            if (("rel" in el) && (el.rel === "prerender"))
                el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
    }

  }

}
</script>