// Functions already in main.js but can not access them from here...
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

$(document).ready(function() {
  favourite.init();
});

let favourites = [];
const favourite = {
	init: function(){
		var _this = this;

		if(!getCookie('favourites')){
			favourites = [];
		} else {
			list = getCookie('favourites');
			favourites = list.split(',');
		}

		$(document).on('click','.toggle-favourite', function(e){
			data = $(this).data('id');
			var item = $(this);
			// Add to favourites
			if(favourites.indexOf(String(data)) < 0){
				favourites.push(String($(this).data('id')));
				setCookie('favourites', favourites.join(','), 3600);
				// hide tooltip and update copy
				if ( item.attr('data-toggle','tooltip') ) {
					item.tooltip('hide');
					item.attr('title',item.attr('data-tooltip-unsave'));
					item.attr('data-original-title',item.attr('data-tooltip-unsave'));
				}
			}
			// Remove from favourites
			else {
				favourites.splice(favourites.indexOf(data.toString()), 1);
				setCookie('favourites', favourites.join(','), 3600);
				// hide tooltip and update copy
				if ( item.attr('data-toggle','tooltip') ) {
					item.tooltip('hide');
					item.attr('title',item.attr('data-tooltip-save'));
					item.attr('data-original-title',item.attr('data-tooltip-save'));
				}

				if(favourites.length == 0){
					$('.favourites-container').remove();
				}
				if($('#main[data-page-key="favourites"]').length) {
					$('[data-id="'+data+'"]').parents('.property-card').parent().remove();
				}
			}

			favourite.update();
		});

		$(document).on('click','.clear-properties', function(e){
			setCookie('favourites', [], 3600);
			favourites = [];

			$('.favourites-container').remove();

			favourite.update();
		});

		$(document).on('submit', '.liquid_share_favourites_form', function(e){
			e.preventDefault();
			e.stopImmediatePropagation();

			var form = $(this);
			var favourites = _this.readCookie('favourites');
			var formData = form.serialize() + '&action=process_liquid_share_favourites_form&favourites=' + favourites;
            var spinner = form.find(".form-btn");

            // show spinner
            spinner.addClass('loading');

			var deferred_request = $.ajax({
				method: "POST",
				url: admin_ajax_url,
				data: formData,
				datatype: "JSON"
			});

			deferred_request.done(function (data) {
                // hide spinner
                spinner.removeClass('loading');
				if(data) {
					data = $.parseJSON(data);
				}

				if(data.success) {
					$('.favourites-presubmit').fadeOut(500, function(){
             $('.favourites-success').fadeIn(500);
          });
				} else {
					form.find('.form-error-msg').text(data.message);
				}
			});

			deferred_request.fail(function (data) {
                // hide spinner
                spinner.removeClass('loading');
				form.find('.form-error-msg').text(data.message);
				console.log('Failed AJAX Call /// Return Data: ' + data);
			});
		});

		$(document).on('show.bs.modal', '#favourites_modal', function(){
			var form = $('.liquid_share_favourites_form');

			$('.favourites-success').hide();
			$('.favourites-presubmit').show();

			form.find('.form-control').val('');
			form.find('#sender_message').val(form.find('#sender_message').attr('data-default-val'));
			form.find('.form-error-msg').text('');
			form.find('#shortlist_privacy_policy_field').prop('checked', false);
		});

		favourite.update();

		if($('#main[data-page-key="favourites"]').length < 1) {
			return false;
		}

		this.loadFavourites();
	},

	update: function(){
		// all inactive
		$('.toggle-favourite').removeClass('full');
		$('.toggle-favourite[data-toggle="tooltip"]').attr('title','Add to Favorites');
		$('.toggle-favourite[data-toggle="tooltip"]').attr('data-original-title','Add to Favorites');

		if(favourites.constructor !== Array) {
			favourites = favourites.split(',');
		}

		// activate the ones already added to favorites
		for(var i=0;i<favourites.length;i++){
			var item = $('.toggle-favourite[data-id="'+favourites[i]+'"]');
			item.addClass('full');
			item.attr('title','Remove from Favorites');
			item.attr('data-original-title','Remove from Favorites');
		}
		
		// update total count
		$('.favourites-count .number').text(favourites.length);

		// animate icon
    	$('.favourites-count .favourite-circle').addClass('animate');
    	setTimeout(function(){
	      	$('.favourites-count .favourite-circle').removeClass('animate');
		},500);
	},

	destroy: function(){
		$(document).off('click','.toggle-favourite');
		$(document).off('click','.clear-properties');
	},

	readCookie: function(name){
		var nameEQ = name + "=";
		var ca = window.document.cookie.split(';');

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}

		return null;
	},

	loadFavourites: function() {
		var self = this;

		if(!self.readCookie('favourites')) {
			return;
		}

		favourites = self.readCookie('favourites');

		$.ajax({
		   type : "post",
		   url : admin_ajax_url,
		   data : {
			   action: "get_favourite_cards",
			   filters: {
				   favourites: favourites
			   }
		   },
		   success: function(response) {
			   result = JSON.parse(response);
			   $('.properties-grid').html(result.properties);
			   $('.rentals-grid').html(result.rentals);

			   if(result.properties || result.rentals) {
				   $('.share-remove-favourites').fadeIn(500);
			   }

			   if(result.properties) {
				   $('.favourites-property-container').fadeIn(500);
			   }

			   if(result.rentals) {
				   $('.favourites-rental-container').fadeIn(500);
			   }

			   if(result.adjusted) {
				   setCookie('favourites', result.adjusted_ids.join(','), 3600);
				   $('.favourites-adjusted-message').show();
				   favourite.update();
			   }

			   initCardSliders();
			   lazyimgs.init();
			   favourite.update();
		   }
		});
	}
};
