// Using Flickity

var bp_sm = '800';

$(document).ready(function() {
  initProjectHeroSliders();
});

// ======== PROJECT HERO SLIDER

var projectHeroSlider_instances = [];

var initProjectHeroSliders = function() {

	var projectHeroSlider = $('.project-hero-slider-container');

	if ( projectHeroSlider.length ) {
		projectHeroSlider.each(function(){
			projectHeroSlider_instances.push(initProjectHeroSlider($(this)));
		})

	}
}
var destroyProjectHeroSliders = function() {
	var instance;
	while (projectHeroSlider_instances.length) {
		instance = projectHeroSlider_instances.shift();
		instance.destroy();
	}
}

var initProjectHeroSlider = function(container) {

	var projectHeroSlider = {
		breakpoint: '1279px',
		initialized: false,
		container: null,
		slider: null,

		updateCount: function(selectedId) {
			var _this = this;
			var num = ('0' + (selectedId + 1)).slice(-2);
      _this.container.find('.slider-count .current').html(num);
		},

		draw: function(){
			var _this = this;

			_this.container.removeClass('loading');

			_this.slider.flickity({
				cellAlign: 0,
				freeScroll: true,
				prevNextButtons: false,
				pageDots: false,
				contain: true
				// setGallerySize: false
			});
			_this.el = _this.slider;

			_this.initialized = true;
			_this.updateCount(0);

			_this.el.on( 'select.flickity', function( event, index ) {
			  _this.updateCount(index);
			});


		},

		destroy: function() {
			var _this = this;
			if ( !_this.el ) return;

			_this.el.destroy();

			_this.container.addClass('loading');
			_this.initialized = false;
		},

		resize: function() {
			var _this = this;
			if ( !_this.el ) return;

			_this.el.resize();
		},

		reset: function() {
			var _this = this;
			_this.destroy();
			_this.draw();
		},
		init: function(container) {
			var _this = this;

			if (container === undefined || container.length < 1) return;

			// === Variables
			_this.container = container;
			_this.slider = _this.container.find('.slider');

			//Draw slider once all images have loaded
			var loaded = 0;
			var imgs = _this.slider.find('img');

			imgs.each(function(){
				var img = $(this);
				if ( Modernizr.mq("screen and (max-width:" + bp_sm + "px)") ) {
					img.attr("data-lazy",  $(this).attr('data-mobileImage'));
				} 
				// Desktop image (992px + )
				else {
					img.attr("data-lazy",  $(this).attr('data-fullImage'));
				}
				img.attr("src", $(this).attr('data-lazy'));
			})

			imgs.one("load", function() {
				loaded++;
				if ( loaded < imgs.length ) { return; }

				_this.slides = _this.slider.html();

				_this.draw();
				window.galleryModal.init();

				_this.el.on( 'staticClick.flickity', function( event, pointer, cellElement, cellIndex ) {
				  if ( $(event.target).is('img') || $(event.target).is('.img-container') || $(event.target).is('.play-icon') ) {
				  	// fade in modal
			    	window.galleryModal.show();
			    	//happen gallery to modal and go to slide
			    	window.galleryModal.showGallery(_this.slides, cellIndex, _this.slider);
				  }
				});

			}).each(function() {
			  if(this.complete) $(this).load();
			});

			return _this;
		}
	}

	return projectHeroSlider.init(container);
	
}

