<template>
    <vuetable ref="vuetable" :api-mode="false" :data="tableData" :fields="fields"></vuetable>
</template>

<script>
import Vuetable from 'vuetable-2';

export default {
    components: {
        Vuetable
    },
    props: {
        rows: {type: String, default: []},
    },
    computed: {
        tableData: function () {
            return JSON.parse(this.rows.replace(/__c/g, ''));
        }
    },
    data: function() {
        return {
            fields: [{
                name: 'name',
                title: 'Name',
                formatter: function (v, _this) {
                    let obj = _this.data.find(o => o.name === v);
                    return '<a href="' + window.location.pathname + '/property/' + obj.sfid + '">' + v + '</a>';
                }
            },
            {
                name: 'selection_status',
                title: 'Status',
                formatter: function (v, _this) {
                    let obj = _this.data.find(o => o.name === v);
                    return '<span class="' + v.toLowerCase() + '">' + v + '</span>';
                }
            },
            {
                name: 'bedrooms',
                title: 'Beds'
            },{
                name: 'bathrooms',
                title: 'Baths'
            },
            /*{
                name: 'polygon_coordinates',
                title: 'Lot Coordinates'
            },*/
            {
                name: 'list_price',
                title: 'Price',
                formatter: function (v) {
                    return '$' + parseInt(v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
             }]
        };
    }
}
</script>
<style>
.available{
    color: green;
}
</style>

